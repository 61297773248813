@charset "UTF-8";
/*html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
p,
dl,
dt,
dd,
ul,
ol,
li,
strong,
span,
small,
label,
i {
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-weight: 400;
}
a,
img {
  border: 0;
}
a {
  text-decoration: none;
  font-family: inherit;
}
i,
em {
  font-style: normal;
}
ul li,
ol li {
  list-style: none;
}
figure {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-stretch: normal;
}
button,
input,
optgroup,
select,
textarea {
  display: inline-block;
  font-family: inherit;*/
/* 1 */
/*font-size: 100%;*/
/* 1 */
/*line-height: 1;*/
/* 1 */
/*margin: 0;*/
/* 2 */
/*}
textarea {
  resize: none;
}
input[type=tel],
input[type=text],
input[type=email],
input[type=date],
input[type=number],
input[type=password],
input[type=submit],
input[type=button],
input[type=reset],
textarea {
  box-sizing: border-box;
  border: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
button,
input {*/
/* 1 */
/*overflow: visible;
}
button,
select {*/
/* 1 */
/*text-transform: none;
}
select {
  vertical-align: top;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
select::-ms-expand {
  display: none;
  display: hidden;
}
fieldset,
a,
img {
  border: 0;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  -webkit-appearance: button;
  outline: none;
  cursor: pointer;
  line-height: 1;
}
caption,
legend {
  width: 0;
  height: 0;
  opacity: 0;
  text-indent: -9999px;
  line-height: 0;
  overflow: hidden;
}
html,
body {
  font-family: inherit, sans-serif;
  height: 100%;
}
.skip-navi a {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 1px;
  width: 0.0625rem;
  height: 1px;
  height: 0.0625rem;
  text-align: center;
  color: #ffffff;
  z-index: 100;
}
.skip-navi a:focus,
.skip-navi a:active {
  width: 100%;
  padding-top: 10px;
  padding-top: 0.625rem;
  padding-right: 0px;
  padding-right: 0rem;
  padding-bottom: 10px;
  padding-bottom: 0.625rem;
  padding-left: 0px;
  padding-left: 0rem;
  height: auto;
  background: #000000;
}
.blind {
  position: absolute !important;
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0px, 0px, 0px, 0px);
  overflow: hidden;
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoCjC3Ttw.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoCxCvTtw.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/NotoSansKR-Thin.woff2') format('woff2'), url('../fonts/NotoSansKR-Thin.woff') format('woff'), url('../fonts/NotoSansKR-Thin.otf') format('truetype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/NotoSansKR-Light.woff2') format('woff2'), url('../fonts/NotoSansKR-Light.woff') format('woff'), url('../fonts/NotoSansKR-Light.otf') format('truetype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/NotoSansKR-Regular.woff2') format('woff2'), url('../fonts/NotoSansKR-Regular.woff') format('woff'), url('../fonts/NotoSansKR-Regular.otf') format('truetype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/NotoSansKR-Medium.woff2') format('woff2'), url('../fonts/NotoSansKR-Medium.woff') format('woff'), url('../fonts/NotoSansKR-Medium.otf') format('truetype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/NotoSansKR-Bold.woff2') format('woff2'), url('../fonts/NotoSansKR-Bold.woff') format('woff'), url('../fonts/NotoSansKR-Bold.otf') format('truetype');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/NotoSansKR-Black.woff2') format('woff2'), url('../fonts/NotoSansKR-Black.woff') format('woff'), url('../fonts/NotoSansKR-Black.otf') format('truetype');
}
.h100 {
  height: 100px !important;
  height: 6.25rem !important;
}
.w100 {
  width: 100px !important;
  width: 6.25rem !important;
}
.pt0 {
  padding-top: 0px !important;
  padding-top: 0rem !important;
}
.pt5 {
  padding-top: 5px !important;
  padding-top: 0.3125rem !important;
}
.pt10 {
  padding-top: 10px !important;
  padding-top: 0.625rem !important;
}
.pt15 {
  padding-top: 15px !important;
  padding-top: 0.9375rem !important;
}
.pt20 {
  padding-top: 20px !important;
  padding-top: 1.25rem !important;
}
.pt25 {
  padding-top: 25px !important;
  padding-top: 1.5625rem !important;
}
.pt30 {
  padding-top: 30px !important;
  padding-top: 1.875rem !important;
}
.pt35 {
  padding-top: 35px !important;
  padding-top: 2.1875rem !important;
}
.pt40 {
  padding-top: 40px !important;
  padding-top: 2.5rem !important;
}
.pt45 {
  padding-top: 45px !important;
  padding-top: 2.8125rem !important;
}
.pt50 {
  padding-top: 50px !important;
  padding-top: 3.125rem !important;
}
.pr0 {
  padding-right: 0px !important;
  padding-right: 0rem !important;
}
.pr5 {
  padding-right: 5px !important;
  padding-right: 0.3125rem !important;
}
.pr10 {
  padding-right: 10px !important;
  padding-right: 0.625rem !important;
}
.pr15 {
  padding-right: 15px !important;
  padding-right: 0.9375rem !important;
}
.pr20 {
  padding-right: 20px !important;
  padding-right: 1.25rem !important;
}
.pr25 {
  padding-right: 25px !important;
  padding-right: 1.5625rem !important;
}
.pr30 {
  padding-right: 30px !important;
  padding-right: 1.875rem !important;
}
.pr35 {
  padding-right: 35px !important;
  padding-right: 2.1875rem !important;
}
.pr40 {
  padding-right: 40px !important;
  padding-right: 2.5rem !important;
}
.pr45 {
  padding-right: 45px !important;
  padding-right: 2.8125rem !important;
}
.pr50 {
  padding-right: 50px !important;
  padding-right: 3.125rem !important;
}
.pb0 {
  padding-bottom: 0px !important;
  padding-bottom: 0rem !important;
}
.pb5 {
  padding-bottom: 5px !important;
  padding-bottom: 0.3125rem !important;
}
.pb10 {
  padding-bottom: 10px !important;
  padding-bottom: 0.625rem !important;
}
.pb15 {
  padding-bottom: 15px !important;
  padding-bottom: 0.9375rem !important;
}
.pb20 {
  padding-bottom: 20px !important;
  padding-bottom: 1.25rem !important;
}
.pb25 {
  padding-bottom: 25px !important;
  padding-bottom: 1.5625rem !important;
}
.pb30 {
  padding-bottom: 30px !important;
  padding-bottom: 1.875rem !important;
}
.pb35 {
  padding-bottom: 35px !important;
  padding-bottom: 2.1875rem !important;
}
.pb40 {
  padding-bottom: 40px !important;
  padding-bottom: 2.5rem !important;
}
.pb45 {
  padding-bottom: 45px !important;
  padding-bottom: 2.8125rem !important;
}
.pb50 {
  padding-bottom: 50px !important;
  padding-bottom: 3.125rem !important;
}
.pl0 {
  padding-left: 0px !important;
  padding-left: 0rem !important;
}
.pl5 {
  padding-left: 5px !important;
  padding-left: 0.3125rem !important;
}
.pl10 {
  padding-left: 10px !important;
  padding-left: 0.625rem !important;
}
.pl15 {
  padding-left: 15px !important;
  padding-left: 0.9375rem !important;
}
.pl20 {
  padding-left: 20px !important;
  padding-left: 1.25rem !important;
}
.pl25 {
  padding-left: 25px !important;
  padding-left: 1.5625rem !important;
}
.pl30 {
  padding-left: 30px !important;
  padding-left: 1.875rem !important;
}
.pl35 {
  padding-left: 35px !important;
  padding-left: 2.1875rem !important;
}
.pl40 {
  padding-left: 40px !important;
  padding-left: 2.5rem !important;
}
.pl45 {
  padding-left: 45px !important;
  padding-left: 2.8125rem !important;
}
.pl50 {
  padding-left: 50px !important;
  padding-left: 3.125rem !important;
}
.mt0 {
  margin-top: 0px !important;
  margin-top: 0rem !important;
}
.mt5 {
  margin-top: 5px !important;
  margin-top: 0.3125rem !important;
}
.mt10 {
  margin-top: 10px !important;
  margin-top: 0.625rem !important;
}
.mt15 {
  margin-top: 15px !important;
  margin-top: 0.9375rem !important;
}
.mt20 {
  margin-top: 20px !important;
  margin-top: 1.25rem !important;
}
.mt25 {
  margin-top: 25px !important;
  margin-top: 1.5625rem !important;
}
.mt30 {
  margin-top: 30px !important;
  margin-top: 1.875rem !important;
}
.mt35 {
  margin-top: 35px !important;
  margin-top: 2.1875rem !important;
}
.mt40 {
  margin-top: 40px !important;
  margin-top: 2.5rem !important;
}
.mt45 {
  margin-top: 45px !important;
  margin-top: 2.8125rem !important;
}
.mt50 {
  margin-top: 50px !important;
  margin-top: 3.125rem !important;
}
.mr0 {
  margin-right: 0px !important;
  margin-right: 0rem !important;
}
.mr5 {
  margin-right: 5px !important;
  margin-right: 0.3125rem !important;
}
.mr10 {
  margin-right: 10px !important;
  margin-right: 0.625rem !important;
}
.mr15 {
  margin-right: 15px !important;
  margin-right: 0.9375rem !important;
}
.mr20 {
  margin-right: 20px !important;
  margin-right: 1.25rem !important;
}
.mr25 {
  margin-right: 25px !important;
  margin-right: 1.5625rem !important;
}
.mr30 {
  margin-right: 30px !important;
  margin-right: 1.875rem !important;
}
.mr35 {
  margin-right: 35px !important;
  margin-right: 2.1875rem !important;
}
.mr40 {
  margin-right: 40px !important;
  margin-right: 2.5rem !important;
}
.mr45 {
  margin-right: 45px !important;
  margin-right: 2.8125rem !important;
}
.mr50 {
  margin-right: 50px !important;
  margin-right: 3.125rem !important;
}
.mb0 {
  margin-bottom: 0px !important;
  margin-bottom: 0rem !important;
}
.mb5 {
  margin-bottom: 5px !important;
  margin-bottom: 0.3125rem !important;
}
.mb10 {
  margin-bottom: 10px !important;
  margin-bottom: 0.625rem !important;
}
.mb15 {
  margin-bottom: 15px !important;
  margin-bottom: 0.9375rem !important;
}
.mb20 {
  margin-bottom: 20px !important;
  margin-bottom: 1.25rem !important;
}
.mb25 {
  margin-bottom: 25px !important;
  margin-bottom: 1.5625rem !important;
}
.mb30 {
  margin-bottom: 30px !important;
  margin-bottom: 1.875rem !important;
}
.mb35 {
  margin-bottom: 35px !important;
  margin-bottom: 2.1875rem !important;
}
.mb40 {
  margin-bottom: 40px !important;
  margin-bottom: 2.5rem !important;
}
.mb45 {
  margin-bottom: 45px !important;
  margin-bottom: 2.8125rem !important;
}
.mb50 {
  margin-bottom: 50px !important;
  margin-bottom: 3.125rem !important;
}
.ml0 {
  margin-left: 0px !important;
  margin-left: 0rem !important;
}
.ml5 {
  margin-left: 5px !important;
  margin-left: 0.3125rem !important;
}
.ml10 {
  margin-left: 10px !important;
  margin-left: 0.625rem !important;
}
.ml15 {
  margin-left: 15px !important;
  margin-left: 0.9375rem !important;
}
.ml20 {
  margin-left: 20px !important;
  margin-left: 1.25rem !important;
}
.ml25 {
  margin-left: 25px !important;
  margin-left: 1.5625rem !important;
}
.ml30 {
  margin-left: 30px !important;
  margin-left: 1.875rem !important;
}
.ml35 {
  margin-left: 35px !important;
  margin-left: 2.1875rem !important;
}
.ml40 {
  margin-left: 40px !important;
  margin-left: 2.5rem !important;
}
.ml45 {
  margin-left: 45px !important;
  margin-left: 2.8125rem !important;
}
.ml50 {
  margin-left: 50px !important;
  margin-left: 3.125rem !important;
}
.lt0 {
  left: 0px !important;
  left: 0rem !important;
}
.lt5 {
  left: 5px !important;
  left: 0.3125rem !important;
}
.lt10 {
  left: 10px !important;
  left: 0.625rem !important;
}
.lt15 {
  left: 15px !important;
  left: 0.9375rem !important;
}
.lt20 {
  left: 20px !important;
  left: 1.25rem !important;
}
.lt25 {
  left: 25px !important;
  left: 1.5625rem !important;
}
.lt30 {
  left: 30px !important;
  left: 1.875rem !important;
}
.lt35 {
  left: 35px !important;
  left: 2.1875rem !important;
}
.lt40 {
  left: 40px !important;
  left: 2.5rem !important;
}
.lt45 {
  left: 45px !important;
  left: 2.8125rem !important;
}
.lt50 {
  left: 50px !important;
  left: 3.125rem !important;
}
.rt0 {
  right: 0px !important;
  right: 0rem !important;
}
.rt5 {
  right: 5px !important;
  right: 0.3125rem !important;
}
.rt10 {
  right: 10px !important;
  right: 0.625rem !important;
}
.rt15 {
  right: 15px !important;
  right: 0.9375rem !important;
}
.rt20 {
  right: 20px !important;
  right: 1.25rem !important;
}
.rt25 {
  right: 25px !important;
  right: 1.5625rem !important;
}
.rt30 {
  right: 30px !important;
  right: 1.875rem !important;
}
.rt35 {
  right: 35px !important;
  right: 2.1875rem !important;
}
.rt40 {
  right: 40px !important;
  right: 2.5rem !important;
}
.rt45 {
  right: 45px !important;
  right: 2.8125rem !important;
}
.rt50 {
  right: 50px !important;
  right: 3.125rem !important;
}
.flex-wrap {
  display: flex;
  align-items: center;
}
.flex-ct {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.flex-mla {
  margin-left: auto;
  flex: none;
}
.flex-mra {
  margin-right: auto;
  flex: none;
}
.flex-ma {
  margin: 0 auto;
  flex: none;
}
.posi-mra {
  position: absolute !important;
  left: 0;
}
.posi-mla {
  position: absolute !important;
  right: 0;
}
.txt-al {
  text-align: left;
}
.txt-ar {
  text-align: right;
}
.txt-ellip {
  display: -webkit-box;
  overflow: hidden;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.txt-ellip--2 {
  -webkit-line-clamp: 2;
}
.txt-ellip--3 {
  -webkit-line-clamp: 3;
}
.alert-notice {
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  padding-top: 0.625rem;
  padding-right: 15px;
  padding-right: 0.9375rem;
  padding-bottom: 10px;
  padding-bottom: 0.625rem;
  padding-left: 15px;
  padding-left: 0.9375rem;
  border: 1px solid #0373c6;
  background-color: #f1fcff;
}
.alert-notice > i {
  font-size: 20px;
  font-size: 1.25rem;
  color: #0373c6;
}
.alert-notice > span {
  margin-left: 5px;
  margin-left: 0.3125rem;
}
.alert-info {
  color: #ff6868;
}
.alert-info > span {
  font-size: 14px;
  font-size: 0.875rem;
}*/
html {
  font-size: 16px;
  font-size: 1rem;
}
@media all and (max-width: 480px) {
  html {
    font-size: 12px;
    font-size: 3.33333333vw;
  }
}
.btn {
  position: relative;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  color: #000000;
  border-radius: 0px !important;
  border-radius: 0rem !important;
  padding-top: 0px;
  padding-top: 0rem;
  padding-right: 0px;
  padding-right: 0rem;
  padding-bottom: 0px;
  padding-bottom: 0rem;
  padding-left: 0px;
  padding-left: 0rem;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.btn > span,
.btn > div,
.btn > strong {
  position: relative;
  vertical-align: initial;
  padding-top: 0px !important;
  padding-top: 0rem !important;
  padding-right: 0px !important;
  padding-right: 0rem !important;
  padding-bottom: 0px !important;
  padding-bottom: 0rem !important;
  padding-left: 0px !important;
  padding-left: 0rem !important;
  z-index: 2;
}
.btn .icon {
  font-size: 16px;
  font-size: 1rem;
  z-index: 2;
}
.btn.mlg {
  padding-top: 10px;
  padding-top: 0.625rem;
  padding-right: 10px;
  padding-right: 0.625rem;
  padding-bottom: 10px;
  padding-bottom: 0.625rem;
  padding-left: 10px;
  padding-left: 0.625rem;
  min-width: 180px;
  min-width: 11.25rem;
  font-size: 18px;
  font-size: 1.125rem;
}
.btn.mlg > span,
.btn.mlg > strong {
  line-height: 23px;
  line-height: 1.4375rem;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 500;
}
.btn.mlg > strong {
  font-weight: 500;
}
.btn.lg {
  padding-top: 15px;
  padding-top: 0.9375rem;
  padding-right: 15px;
  padding-right: 0.9375rem;
  padding-bottom: 15px;
  padding-bottom: 0.9375rem;
  padding-left: 15px;
  padding-left: 0.9375rem;
  min-width: 250px;
  min-width: 15.625rem;
  font-size: 20px;
  font-size: 1.25rem;
}
.btn.lg > span,
.btn.lg > strong {
  line-height: 25px;
  line-height: 1.5625rem;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 500;
}
.btn.lg > strong {
  font-weight: 500;
}
.btn.blue {
  color: #ffffff;
  background-color: #0373c6;
  border: 1px solid #0373c6;
}
.btn.blue .icon:before,
.btn.blue .icon:after {
  border-color: #ffffff;
}
.btn.blue:hover .icon,
.btn.blue:focus .icon {
  color: #0373c6;
}
.btn.blue:hover .icon:before,
.btn.blue:focus .icon:before,
.btn.blue:hover .icon:after,
.btn.blue:focus .icon:after {
  border-color: #0373c6;
}
.btn.full {
  width: 100%;
}
.btn:disabled {
  cursor: default;
  pointer-events: none;
  opacity: 0.3;
}
.btn:disabled:hover:before {
  display: none;
}
.btn:disabled:hover:after {
  display: none;
}
input[type="radio"] {
  position: absolute;
  width: 1px;
  width: 0.0625rem;
  height: 1px;
  height: 0.0625rem;
  padding-top: 0px;
  padding-top: 0rem;
  padding-right: 0px;
  padding-right: 0rem;
  padding-bottom: 0px;
  padding-bottom: 0rem;
  padding-left: 0px;
  padding-left: 0rem;
  margin-top: -1px;
  margin-top: -0.0625rem;
  margin-right: -1px;
  margin-right: -0.0625rem;
  margin-bottom: -1px;
  margin-bottom: -0.0625rem;
  margin-left: -1px;
  margin-left: -0.0625rem;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
input[type="radio"] + label {
  position: relative;
  padding-left: 23px;
  padding-left: 1.4375rem;
  font-size: 16px;
  font-size: 1rem;
  line-height: 20px;
  line-height: 1.25rem;
  color: #767676;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  cursor: pointer;
  word-break: break-word;
}
input[type="radio"] + label:before {
  content: "";
  position: absolute;
  top: 0px;
  top: 0rem;
  left: 0px;
  left: 0rem;
  width: 16px;
  width: 1rem;
  height: 16px;
  height: 1rem;
  border-radius: 100%;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
input[type="radio"] + label:after {
  content: "";
  position: absolute;
  top: 4px;
  top: 0.25rem;
  left: 4px;
  left: 0.25rem;
  width: 8px;
  width: 0.5rem;
  height: 8px;
  height: 0.5rem;
  border-radius: 100%;
  background-color: #ffffff;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
input[type="radio"]:checked + label {
  color: #000000;
}
input[type="radio"]:checked + label:before {
  border-color: #0373c6;
  background-color: #0373c6;
}
.form-item {
  display: flex;
  flex-direction: column;
  position: relative;
}
.form-item .inp label {
  display: inline-block;
}
.assessment {
  /*max-width: 480px;
  max-width: 30rem;*/
  margin: 0 auto;
}
.assessment .main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.assessment .main > strong {
  font-size: 46px;
  font-size: 2.875rem;
  color: #0373c6;
  font-weight: 700;
}
.assessment .main > p {
  font-size: 20px;
  font-size: 1.25rem;
  color: #0373c6;
  text-align: center;
  margin-top: 20px;
  margin-top: 1.25rem;
  margin-bottom: 40px;
  margin-bottom: 2.5rem;
}
.assessment .main > p > span {
  display: block;
}
.assessment .content > header {
  padding-top: 20px;
  padding-top: 1.25rem;
  padding-right: 20px;
  padding-right: 1.25rem;
  padding-bottom: 20px;
  padding-bottom: 1.25rem;
  padding-left: 20px;
  padding-left: 1.25rem;
  background-color: #dddddd;
}
.assessment .content > header > h1 {
  font-size: 32px;
  font-size: 2rem;
  color: #0373c6;
}
.assessment .content > header > ul {
  display: flex;
  margin-top: 10px;
  margin-top: 0.625rem;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
}
.assessment .content > header > ul > li {
  font-size: 26px;
  font-size: 1.625rem;
  color: #767676;
  letter-spacing: 0.3em;
}
.assessment .content > header > ul > li.active {
  color: #0373c6;
}
.assessment .content > header .ask {
  display: flex;
}
.assessment .content > header .ask > strong {
  flex: none;
  width: 30px;
  width: 1.875rem;
  height: 30px;
  height: 1.875rem;
  line-height: 32px;
  line-height: 2rem;
  border-radius: 100%;
  font-size: 20px;
  font-size: 1.25rem;
  color: #ffffff;
  background-color: #0373c6;
  text-align: center;
}
.assessment .content > header .ask > span {
  margin-left: 10px;
  margin-left: 0.625rem;
  padding-top: 5px;
  padding-top: 0.3125rem;
  font-size: 18px;
  font-size: 1.125rem;
}
.assessment .content > section {
  padding-top: 20px;
  padding-top: 1.25rem;
  padding-right: 20px;
  padding-right: 1.25rem;
  padding-bottom: 20px;
  padding-bottom: 1.25rem;
  padding-left: 20px;
  padding-left: 1.25rem;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}
.assessment .content > section .tit {
  display: flex;
}
.assessment .content > section .tit > p {
  font-size: 18px;
  font-size: 1.125rem;
  margin-left: 10px;
  margin-left: 0.625rem;
}
.assessment .content > section .form-item {
  padding-top: 20px;
  padding-top: 1.25rem;
  padding-right: 20px;
  padding-right: 1.25rem;
  padding-bottom: 20px;
  padding-bottom: 1.25rem;
  padding-left: 20px;
  padding-left: 1.25rem;
}
.assessment .content > section .form-item .inp {
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
}
.assessment .alert-info {
  display: flex;
  padding-top: 15px;
  padding-top: 0.9375rem;
  padding-right: 15px;
  padding-right: 0.9375rem;
  padding-bottom: 15px;
  padding-bottom: 0.9375rem;
  padding-left: 15px;
  padding-left: 0.9375rem;
  border: 1px solid #dddddd;
  color: #000000;
}
.assessment .alert-info > i {
  font-size: 20px;
  font-size: 1.25rem;
  margin-right: 10px;
  margin-right: 0.625rem;
}
.assessment .alert-info > p {
  font-size: 14px;
  font-size: 0.875rem;
}
.assessment .alert-info > p > a {
  color: #000000;
  text-decoration: underline;
}
