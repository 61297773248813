@charset "UTF-8";
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
p,
dl,
dt,
dd,
ul,
ol,
li,
strong,
span,
small,
label,
i {
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-weight: 400;
}
a,
img {
  border: 0;
}
a {
  text-decoration: none;
  font-family: inherit;
}
i,
em {
  font-style: normal;
}
ul li,
ol li {
  list-style: none;
}
figure {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-stretch: normal;
}
button,
input,
optgroup,
select,
textarea {
  display: inline-block;
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1;
  /* 1 */
  margin: 0;
  /* 2 */
}
textarea {
  resize: none;
}
input[type="tel"],
input[type="text"],
input[type="email"],
input[type="date"],
input[type="number"],
input[type="password"],
input[type="submit"],
input[type="button"],
input[type="reset"],
textarea {
  box-sizing: border-box;
  border: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
button,
input {
  /* 1 */
  overflow: visible;
}
button,
select {
  /* 1 */
  text-transform: none;
}
select {
  vertical-align: top;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
select::-ms-expand {
  display: none;
  display: hidden;
}
fieldset,
a,
img {
  border: 0;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  -webkit-appearance: button;
  outline: none;
  cursor: pointer;
  line-height: 1;
}
caption,
legend {
  width: 0;
  height: 0;
  opacity: 0;
  text-indent: -9999px;
  line-height: 0;
  overflow: hidden;
}
html,
body {
  font-family: inherit, sans-serif;
  height: 100%;
}
.skip-navi a {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 1px;
  width: 0.0625rem;
  height: 1px;
  height: 0.0625rem;
  text-align: center;
  color: #ffffff;
  z-index: 100;
}
.skip-navi a:focus,
.skip-navi a:active {
  width: 100%;
  padding-top: 10px;
  padding-top: 0.625rem;
  padding-right: 0px;
  padding-right: 0rem;
  padding-bottom: 10px;
  padding-bottom: 0.625rem;
  padding-left: 0px;
  padding-left: 0rem;
  height: auto;
  background: #000000;
}
.blind {
  position: absolute !important;
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0px, 0px, 0px, 0px);
  overflow: hidden;
}
@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoCjC3Ttw.ttf)
    format("truetype");
}
@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoCxCvTtw.ttf)
    format("truetype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/NotoSansKR-Thin.woff2") format("woff2"),
    url("../fonts/NotoSansKR-Thin.woff") format("woff"),
    url("../fonts/NotoSansKR-Thin.otf") format("truetype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/NotoSansKR-Light.woff2") format("woff2"),
    url("../fonts/NotoSansKR-Light.woff") format("woff"),
    url("../fonts/NotoSansKR-Light.otf") format("truetype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/NotoSansKR-Regular.woff2") format("woff2"),
    url("../fonts/NotoSansKR-Regular.woff") format("woff"),
    url("../fonts/NotoSansKR-Regular.otf") format("truetype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/NotoSansKR-Medium.woff2") format("woff2"),
    url("../fonts/NotoSansKR-Medium.woff") format("woff"),
    url("../fonts/NotoSansKR-Medium.otf") format("truetype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/NotoSansKR-Bold.woff2") format("woff2"),
    url("../fonts/NotoSansKR-Bold.woff") format("woff"),
    url("../fonts/NotoSansKR-Bold.otf") format("truetype");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/NotoSansKR-Black.woff2") format("woff2"),
    url("../fonts/NotoSansKR-Black.woff") format("woff"),
    url("../fonts/NotoSansKR-Black.otf") format("truetype");
}
.h100 {
  height: 100px !important;
  height: 6.25rem !important;
}
.w100 {
  width: 100px !important;
  width: 6.25rem !important;
}
.pt0 {
  padding-top: 0px !important;
  padding-top: 0rem !important;
}
.pt5 {
  padding-top: 5px !important;
  padding-top: 0.3125rem !important;
}
.pt10 {
  padding-top: 10px !important;
  padding-top: 0.625rem !important;
}
.pt15 {
  padding-top: 15px !important;
  padding-top: 0.9375rem !important;
}
.pt20 {
  padding-top: 20px !important;
  padding-top: 1.25rem !important;
}
.pt25 {
  padding-top: 25px !important;
  padding-top: 1.5625rem !important;
}
.pt30 {
  padding-top: 30px !important;
  padding-top: 1.875rem !important;
}
.pt35 {
  padding-top: 35px !important;
  padding-top: 2.1875rem !important;
}
.pt40 {
  padding-top: 40px !important;
  padding-top: 2.5rem !important;
}
.pt45 {
  padding-top: 45px !important;
  padding-top: 2.8125rem !important;
}
.pt50 {
  padding-top: 50px !important;
  padding-top: 3.125rem !important;
}
.pr0 {
  padding-right: 0px !important;
  padding-right: 0rem !important;
}
.pr5 {
  padding-right: 5px !important;
  padding-right: 0.3125rem !important;
}
.pr10 {
  padding-right: 10px !important;
  padding-right: 0.625rem !important;
}
.pr15 {
  padding-right: 15px !important;
  padding-right: 0.9375rem !important;
}
.pr20 {
  padding-right: 20px !important;
  padding-right: 1.25rem !important;
}
.pr25 {
  padding-right: 25px !important;
  padding-right: 1.5625rem !important;
}
.pr30 {
  padding-right: 30px !important;
  padding-right: 1.875rem !important;
}
.pr35 {
  padding-right: 35px !important;
  padding-right: 2.1875rem !important;
}
.pr40 {
  padding-right: 40px !important;
  padding-right: 2.5rem !important;
}
.pr45 {
  padding-right: 45px !important;
  padding-right: 2.8125rem !important;
}
.pr50 {
  padding-right: 50px !important;
  padding-right: 3.125rem !important;
}
.pb0 {
  padding-bottom: 0px !important;
  padding-bottom: 0rem !important;
}
.pb5 {
  padding-bottom: 5px !important;
  padding-bottom: 0.3125rem !important;
}
.pb10 {
  padding-bottom: 10px !important;
  padding-bottom: 0.625rem !important;
}
.pb15 {
  padding-bottom: 15px !important;
  padding-bottom: 0.9375rem !important;
}
.pb20 {
  padding-bottom: 20px !important;
  padding-bottom: 1.25rem !important;
}
.pb25 {
  padding-bottom: 25px !important;
  padding-bottom: 1.5625rem !important;
}
.pb30 {
  padding-bottom: 30px !important;
  padding-bottom: 1.875rem !important;
}
.pb35 {
  padding-bottom: 35px !important;
  padding-bottom: 2.1875rem !important;
}
.pb40 {
  padding-bottom: 40px !important;
  padding-bottom: 2.5rem !important;
}
.pb45 {
  padding-bottom: 45px !important;
  padding-bottom: 2.8125rem !important;
}
.pb50 {
  padding-bottom: 50px !important;
  padding-bottom: 3.125rem !important;
}
.pl0 {
  padding-left: 0px !important;
  padding-left: 0rem !important;
}
.pl5 {
  padding-left: 5px !important;
  padding-left: 0.3125rem !important;
}
.pl10 {
  padding-left: 10px !important;
  padding-left: 0.625rem !important;
}
.pl15 {
  padding-left: 15px !important;
  padding-left: 0.9375rem !important;
}
.pl20 {
  padding-left: 20px !important;
  padding-left: 1.25rem !important;
}
.pl25 {
  padding-left: 25px !important;
  padding-left: 1.5625rem !important;
}
.pl30 {
  padding-left: 30px !important;
  padding-left: 1.875rem !important;
}
.pl35 {
  padding-left: 35px !important;
  padding-left: 2.1875rem !important;
}
.pl40 {
  padding-left: 40px !important;
  padding-left: 2.5rem !important;
}
.pl45 {
  padding-left: 45px !important;
  padding-left: 2.8125rem !important;
}
.pl50 {
  padding-left: 50px !important;
  padding-left: 3.125rem !important;
}
.mt0 {
  margin-top: 0px !important;
  margin-top: 0rem !important;
}
.mt5 {
  margin-top: 5px !important;
  margin-top: 0.3125rem !important;
}
.mt10 {
  margin-top: 10px !important;
  margin-top: 0.625rem !important;
}
.mt15 {
  margin-top: 15px !important;
  margin-top: 0.9375rem !important;
}
.mt20 {
  margin-top: 20px !important;
  margin-top: 1.25rem !important;
}
.mt25 {
  margin-top: 25px !important;
  margin-top: 1.5625rem !important;
}
.mt30 {
  margin-top: 30px !important;
  margin-top: 1.875rem !important;
}
.mt35 {
  margin-top: 35px !important;
  margin-top: 2.1875rem !important;
}
.mt40 {
  margin-top: 40px !important;
  margin-top: 2.5rem !important;
}
.mt45 {
  margin-top: 45px !important;
  margin-top: 2.8125rem !important;
}
.mt50 {
  margin-top: 50px !important;
  margin-top: 3.125rem !important;
}
.mr0 {
  margin-right: 0px !important;
  margin-right: 0rem !important;
}
.mr5 {
  margin-right: 5px !important;
  margin-right: 0.3125rem !important;
}
.mr10 {
  margin-right: 10px !important;
  margin-right: 0.625rem !important;
}
.mr15 {
  margin-right: 15px !important;
  margin-right: 0.9375rem !important;
}
.mr20 {
  margin-right: 20px !important;
  margin-right: 1.25rem !important;
}
.mr25 {
  margin-right: 25px !important;
  margin-right: 1.5625rem !important;
}
.mr30 {
  margin-right: 30px !important;
  margin-right: 1.875rem !important;
}
.mr35 {
  margin-right: 35px !important;
  margin-right: 2.1875rem !important;
}
.mr40 {
  margin-right: 40px !important;
  margin-right: 2.5rem !important;
}
.mr45 {
  margin-right: 45px !important;
  margin-right: 2.8125rem !important;
}
.mr50 {
  margin-right: 50px !important;
  margin-right: 3.125rem !important;
}
.mb0 {
  margin-bottom: 0px !important;
  margin-bottom: 0rem !important;
}
.mb5 {
  margin-bottom: 5px !important;
  margin-bottom: 0.3125rem !important;
}
.mb10 {
  margin-bottom: 10px !important;
  margin-bottom: 0.625rem !important;
}
.mb15 {
  margin-bottom: 15px !important;
  margin-bottom: 0.9375rem !important;
}
.mb20 {
  margin-bottom: 20px !important;
  margin-bottom: 1.25rem !important;
}
.mb25 {
  margin-bottom: 25px !important;
  margin-bottom: 1.5625rem !important;
}
.mb30 {
  margin-bottom: 30px !important;
  margin-bottom: 1.875rem !important;
}
.mb35 {
  margin-bottom: 35px !important;
  margin-bottom: 2.1875rem !important;
}
.mb40 {
  margin-bottom: 40px !important;
  margin-bottom: 2.5rem !important;
}
.mb45 {
  margin-bottom: 45px !important;
  margin-bottom: 2.8125rem !important;
}
.mb50 {
  margin-bottom: 50px !important;
  margin-bottom: 3.125rem !important;
}
.ml0 {
  margin-left: 0px !important;
  margin-left: 0rem !important;
}
.ml5 {
  margin-left: 5px !important;
  margin-left: 0.3125rem !important;
}
.ml10 {
  margin-left: 10px !important;
  margin-left: 0.625rem !important;
}
.ml15 {
  margin-left: 15px !important;
  margin-left: 0.9375rem !important;
}
.ml20 {
  margin-left: 20px !important;
  margin-left: 1.25rem !important;
}
.ml25 {
  margin-left: 25px !important;
  margin-left: 1.5625rem !important;
}
.ml30 {
  margin-left: 30px !important;
  margin-left: 1.875rem !important;
}
.ml35 {
  margin-left: 35px !important;
  margin-left: 2.1875rem !important;
}
.ml40 {
  margin-left: 40px !important;
  margin-left: 2.5rem !important;
}
.ml45 {
  margin-left: 45px !important;
  margin-left: 2.8125rem !important;
}
.ml50 {
  margin-left: 50px !important;
  margin-left: 3.125rem !important;
}
.lt0 {
  left: 0px !important;
  left: 0rem !important;
}
.lt5 {
  left: 5px !important;
  left: 0.3125rem !important;
}
.lt10 {
  left: 10px !important;
  left: 0.625rem !important;
}
.lt15 {
  left: 15px !important;
  left: 0.9375rem !important;
}
.lt20 {
  left: 20px !important;
  left: 1.25rem !important;
}
.lt25 {
  left: 25px !important;
  left: 1.5625rem !important;
}
.lt30 {
  left: 30px !important;
  left: 1.875rem !important;
}
.lt35 {
  left: 35px !important;
  left: 2.1875rem !important;
}
.lt40 {
  left: 40px !important;
  left: 2.5rem !important;
}
.lt45 {
  left: 45px !important;
  left: 2.8125rem !important;
}
.lt50 {
  left: 50px !important;
  left: 3.125rem !important;
}
.rt0 {
  right: 0px !important;
  right: 0rem !important;
}
.rt5 {
  right: 5px !important;
  right: 0.3125rem !important;
}
.rt10 {
  right: 10px !important;
  right: 0.625rem !important;
}
.rt15 {
  right: 15px !important;
  right: 0.9375rem !important;
}
.rt20 {
  right: 20px !important;
  right: 1.25rem !important;
}
.rt25 {
  right: 25px !important;
  right: 1.5625rem !important;
}
.rt30 {
  right: 30px !important;
  right: 1.875rem !important;
}
.rt35 {
  right: 35px !important;
  right: 2.1875rem !important;
}
.rt40 {
  right: 40px !important;
  right: 2.5rem !important;
}
.rt45 {
  right: 45px !important;
  right: 2.8125rem !important;
}
.rt50 {
  right: 50px !important;
  right: 3.125rem !important;
}
.flex-wrap {
  display: flex;
  align-items: center;
}
.flex-ct {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.flex-mla {
  margin-left: auto;
  flex: none;
}
.flex-mra {
  margin-right: auto;
  flex: none;
}
.flex-ma {
  margin: 0 auto;
  flex: none;
}
.posi-mra {
  position: absolute !important;
  left: 0;
}
.posi-mla {
  position: absolute !important;
  right: 0;
}
.txt-al {
  text-align: left;
}
.txt-ar {
  text-align: right;
}
.txt-ellip {
  display: -webkit-box;
  overflow: hidden;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.txt-ellip--2 {
  -webkit-line-clamp: 2;
}
.txt-ellip--3 {
  -webkit-line-clamp: 3;
}
.alert-notice {
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  padding-top: 0.625rem;
  padding-right: 15px;
  padding-right: 0.9375rem;
  padding-bottom: 10px;
  padding-bottom: 0.625rem;
  padding-left: 15px;
  padding-left: 0.9375rem;
  border: 1px solid #0373c6;
  background-color: #f1fcff;
}
.alert-notice > i {
  font-size: 20px;
  font-size: 1.25rem;
  color: #0373c6;
}
.alert-notice > span {
  margin-left: 5px;
  margin-left: 0.3125rem;
}
.alert-info {
  color: #ff6868;
}
.alert-info > span {
  font-size: 14px;
  font-size: 0.875rem;
}
html {
  min-width: 320px;
  min-width: 20rem;
  font-size: 16px;
  font-size: 1rem;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 400;
}
html .wrap {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
html .wrap > header {
  width: 100%;
}
html .wrap > main {
  width: 1240px;
  width: 77.5rem;
  margin: 0 auto;
  min-height: calc(100% - 151px);
}
html .wrap > footer {
  display: flex;
  align-items: flex-end;
  margin-top: auto;
  width: 100%;
}
html .trans-wrap {
  display: none;
  position: fixed;
  left: 0px;
  left: 0rem;
  top: 0px;
  top: 0rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #000000;
  opacity: 0;
  z-index: 11;
}
.header {
  border-bottom: 1px solid #dddddd;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
.header__wrap {
  position: relative;
  display: flex;
  align-items: center;
  width: 1240px;
  width: 77.5rem;
  height: 70px;
  height: 4.375rem;
  margin: 0 auto;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.header__wrap > h1 > a {
  display: block;
  width: 120px;
  width: 7.5rem;
  height: 40px;
  height: 2.5rem;
  background: url("../images/logo.png") 0 0 no-repeat;
  background-size: 120px 40px;
}
.header .util {
  position: relative;
  margin-left: auto;
  display: flex;
  align-items: center;
}
.header .util .icon-wrap {
  position: relative;
}
.header .util .icon-wrap .icon-img {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
  color: #767676;
  margin-right: 10px;
  margin-right: 0.625rem;
}
.header .util .user-name {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 500;
}
.header .util .menu-all {
  padding-top: 0px;
  padding-top: 0rem;
  padding-right: 10px;
  padding-right: 0.625rem;
  padding-bottom: 0px;
  padding-bottom: 0rem;
  padding-left: 10px;
  padding-left: 0.625rem;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.header .util .menu-all.on {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.header .util .menu-all .icon-arrow {
  font-size: 26px;
  font-size: 1.625rem;
}
.header .gnb-wrap {
  position: relative;
  width: 1240px;
  width: 77.5rem;
  margin: 0 auto;
  box-sizing: border-box;
}
.header .gnb {
  display: none;
  position: absolute;
  right: 0px;
  right: 0rem;
  top: 0px;
  top: 0rem;
  width: 280px;
  width: 17.5rem;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 12;
}
.header .gnb.on {
  display: block;
}
.header .gnb ul > li {
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.header .gnb ul > li > a,
.header .gnb ul > li > p,
.header .gnb ul > li > div {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-top: 0.625rem;
  padding-right: 10px;
  padding-right: 0.625rem;
  padding-bottom: 10px;
  padding-bottom: 0.625rem;
  padding-left: 10px;
  padding-left: 0.625rem;
  line-height: 18px;
  line-height: 1.125rem;
  color: #000000;
  box-sizing: border-box;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.header .gnb ul > li > a > i,
.header .gnb ul > li > div > i,
.header .gnb ul > li > p > i {
  width: 30px;
  width: 1.875rem;
  font-size: 18px;
  font-size: 1.125rem;
}
.header .gnb ul > li > a > span,
.header .gnb ul > li > div > span,
.header .gnb ul > li > p > span {
  font-size: 14px;
  font-size: 0.875rem;
}
.header .gnb ul.depth01 > li {
  border-bottom: 1px solid #dddddd;
}
.header .gnb ul.depth01 > li > a:hover,
.header .gnb ul.depth01 > li > div:hover,
.header .gnb ul.depth01 > li > a:focus {
  outline: none;
  color: #ffffff;
  background-color: #0373c6;
}
.header .gnb ul.depth01 > li > p {
  color: #0373c6;
  border-bottom: 1px solid #dddddd;
}
.header .gnb ul.depth02 {
  padding-top: 10px;
  padding-top: 0.625rem;
  padding-bottom: 10px;
  padding-bottom: 0.625rem;
}
.header .gnb ul.depth02 > li {
  padding-left: 30px;
  padding-left: 1.875rem;
}
.header .gnb ul.depth02 > li:last-child {
  border-bottom: none;
}
.header .gnb ul.depth02 > li > a {
  padding-top: 5px;
  padding-top: 0.3125rem;
  padding-right: 5px;
  padding-right: 0.3125rem;
  padding-bottom: 5px;
  padding-bottom: 0.3125rem;
  padding-left: 5px;
  padding-left: 0.3125rem;
}
.header .gnb ul.depth02 > li > a > i {
  font-size: 15px;
  font-size: 0.9375rem;
}
.header .gnb ul.depth02 > li:hover {
  background-color: #0373c6;
}
.header .gnb ul.depth02 > li:hover > a {
  color: #ffffff;
}
.footer {
  padding-top: 30px;
  padding-top: 1.875rem;
  padding-right: 30px;
  padding-right: 1.875rem;
  padding-bottom: 30px;
  padding-bottom: 1.875rem;
  padding-left: 30px;
  padding-left: 1.875rem;
  box-sizing: border-box;
}
.footer__wrap {
  width: 1240px;
  width: 77.5rem;
  margin: 0 auto;
}
.footer__wrap > p.copy {
  font-size: 14px;
  font-size: 0.875rem;
  color: #767676;
  text-transform: uppercase;
  text-align: center;
}
@media all and (max-width: 1240px) {
  html .wrap > main {
    width: 100%;
  }
  .header__wrap {
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
    box-sizing: border-box;
  }
  .header .gnb-wrap {
    width: 100%;
  }
  .header .gnb {
    margin-right: 2%;
  }
  .header .gnb > ul {
    width: 100%;
  }
  .footer__wrap {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  html .wrap > main {
    min-height: calc(100% - 141px);
  }
  .header__wrap {
    height: 60px;
    height: 3.75rem;
    padding-left: 3%;
    padding-right: 3%;
  }
  .header__wrap > h1 > a {
    width: 99px;
    width: 6.1875rem;
    height: 33px;
    height: 2.0625rem;
    background-size: 99px 33px;
  }
  .header .gnb {
    position: relative;
    top: 0px;
    top: 0rem;
    width: 100%;
  }
}
@media all and (max-width: 480px) {
  .header .util .user-name {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .header .util .menu-all {
    padding-top: 0px;
    padding-top: 0rem;
    padding-right: 5px;
    padding-right: 0.3125rem;
    padding-bottom: 0px;
    padding-bottom: 0rem;
    padding-left: 5px;
    padding-left: 0.3125rem;
  }
}
.login-page:before {
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  left: 0rem;
  top: 0px;
  top: 0rem;
  width: 100%;
  height: calc(100vh - 50%);
  border-bottom: 7px solid #0373c6;
  background-color: #0c1944;
  z-index: -1;
}
.login-page .login {
  width: 400px;
  width: 25rem;
}
.login-page .login > header {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
}
.login-page .login > header .lang {
  width: 140px;
  width: 8.75rem;
}
.login-page .login__wrap {
  padding-top: 40px;
  padding-top: 2.5rem;
  padding-right: 40px;
  padding-right: 2.5rem;
  padding-bottom: 40px;
  padding-bottom: 2.5rem;
  padding-left: 40px;
  padding-left: 2.5rem;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
}
.login-page .login__form {
  max-width: 270px;
  max-width: 16.875rem;
  margin: 0 auto;
}
.login-page .login__form .name_wrap {
  line-height: 56px;
  line-height: 3.5rem;
  margin-bottom: 30px;
  margin-bottom: 1.875rem;
  text-align: center;
}
.login-page .login__form .name_wrap > span {
  display: block;
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
}
.login-page .login__form .name_wrap > span.blue {
  font-size: 56px;
  font-size: 3.5rem;
  color: #0373c6;
}
.login-page .login__form .name_wrap > span.navy {
  padding-left: 0.6rem;
  font-size: 3.1rem;
  color: #0c1944;
}
.login-page .login__form .name_wrap > span.grey {
  padding-left: 0.6rem;
  font-size: 3.1rem;
  color: #767676;
}
.login-page .login__form .tf,
.login-page .login__form .inp {
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
}
.main-area {
  position: relative;
  padding-top: 20px;
  padding-top: 1.25rem;
  box-sizing: border-box;
}
.main-area .main-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}
.main-area .main-top > p.date > span {
  color: #0373c6;
}
.main-area .grid-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-area .grid-icon > i {
  font-size: 150px;
  font-size: 9.375rem;
  color: #000000;
}
.main-area dl {
  border: 1px solid #dddddd;
}
.main-area dl dt {
  padding-top: 20px;
  padding-top: 1.25rem;
  padding-right: 20px;
  padding-right: 1.25rem;
  padding-bottom: 20px;
  padding-bottom: 1.25rem;
  padding-left: 20px;
  padding-left: 1.25rem;
  border-bottom: 1px solid #dddddd;
  background-color: rgba(0, 0, 0, 0.02);
}
.main-area dl dt > strong {
  font-size: 20px;
  font-size: 1.25rem;
  color: #0373c6;
  font-weight: 700;
  margin-right: 5px;
  margin-right: 0.3125rem;
}
.main-area dl dt > span {
  font-size: 18px;
  font-size: 1.125rem;
  color: #767676;
}
.main-area dl dd {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 20px;
  padding-top: 1.25rem;
  padding-right: 20px;
  padding-right: 1.25rem;
  padding-bottom: 20px;
  padding-bottom: 1.25rem;
  padding-left: 20px;
  padding-left: 1.25rem;
}
.main-area dl dd > .grid-bar {
  display: block;
  width: 1px;
  width: 0.0625rem;
  height: 138px;
  height: 8.625rem;
  background-color: #dddddd;
}
.main-area .item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding-top: 20px;
  padding-top: 1.25rem;
  padding-right: 10px;
  padding-right: 0.625rem;
  padding-bottom: 20px;
  padding-bottom: 1.25rem;
  padding-left: 10px;
  padding-left: 0.625rem;
  box-sizing: border-box;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.main-area .item > header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-area .item > header .num {
  text-align: center;
  cursor: pointer;
}
.main-area .item > header .num > a {
  font-size: 40px;
  font-size: 2.5rem;
  font-weight: 600;
}
.main-area .item > header .num > a:hover {
  text-decoration: underline;
}
.main-area .item > header .num > a.yellow {
  color: #ffa93c;
}
.main-area .item > header .num > a.blue {
  color: #0373c6;
}
.main-area .item > header .num > a.red {
  color: #ff6868;
}
.main-area .item > header .num > a.green {
  color: #05a705;
}
.main-area .item > header .num > span.txt {
  font-size: 22px;
  font-size: 1.375rem;
  color: #767676;
}
.main-area .item > strong {
  margin-top: 15px;
  margin-top: 0.9375rem;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  color: #767676;
  text-transform: uppercase;
}
.super-area {
  position: relative;
  padding-top: 20px;
  padding-top: 1.25rem;
  box-sizing: border-box;
}
.super-area .super-top {
  margin-bottom: 30px;
  margin-bottom: 1.875rem;
}
.super-area .super-cont .total-item {
  display: flex;
  justify-content: space-around;
  padding-bottom: 40px;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #dddddd;
}
.super-area .super-cont .total-item .item {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.super-area .super-cont .total-item .item > strong {
  color: #0373c6;
  font-size: 46px;
  font-size: 2.875rem;
  font-weight: 700;
}
.super-area .super-cont .total-item .item > span {
  font-size: 16px;
  font-size: 1rem;
}
.super-area .super-cont .adm-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.super-area .super-cont .adm-wrap > article {
  padding-top: 30px;
  padding-top: 1.875rem;
}
.super-area .super-cont .adm-wrap > article > h2 {
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 700;
  color: #0373c6;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}
.super-area .super-cont .adm-wrap > article + article {
  margin-left: 50px;
  margin-left: 3.125rem;
}
.super-area .super-cont .adm-wrap .adm-reg {
  display: flex;
  flex-direction: column;
  width: 35%;
}
.super-area .super-cont .adm-wrap .adm-reg__insert {
  padding-top: 20px;
  padding-top: 1.25rem;
  padding-right: 20px;
  padding-right: 1.25rem;
  padding-bottom: 20px;
  padding-bottom: 1.25rem;
  padding-left: 20px;
  padding-left: 1.25rem;
  border: 1px solid #dddddd;
}
.super-area .super-cont .adm-wrap .adm-reg__insert > li {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}
.super-area .super-cont .adm-wrap .adm-reg__insert > li:last-child {
  margin-bottom: 0px;
  margin-bottom: 0rem;
}
.super-area .super-cont .adm-wrap .adm-reg__insert > li > p {
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
}
.super-area .super-cont .adm-wrap .adm-reg__insert > li > p > i {
  color: #0373c6;
  margin-right: 5px;
  margin-right: 0.3125rem;
}
.super-area .super-cont .adm-wrap .adm-list {
  width: 65%;
}
.sub-top {
  box-sizing: border-box;
}
.sub-top > header {
  display: flex;
  flex-direction: column;
}
.sub-top > header h2 {
  display: flex;
  align-items: center;
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 500;
  margin-top: 20px;
  margin-top: 1.25rem;
  padding-bottom: 20px;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #dddddd;
}
.sub-top > header h2 > span + span {
  margin-left: 10px;
  margin-left: 0.625rem;
}
.sub-top > header h3 {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 500;
}
.sub-top > header .page-history {
  padding-top: 15px;
  padding-top: 0.9375rem;
  padding-right: 10px;
  padding-right: 0.625rem;
  padding-bottom: 15px;
  padding-bottom: 0.9375rem;
  padding-left: 0px;
  padding-left: 0rem;
  /* margin-left: auto; */
}
.sub-top > header .page-history > ul {
  display: flex;
}
.sub-top > header .page-history > ul > li {
  position: relative;
  padding-top: 0px;
  padding-top: 0rem;
  padding-right: 13px;
  padding-right: 0.8125rem;
  padding-bottom: 0px;
  padding-bottom: 0rem;
  padding-left: 17px;
  padding-left: 1.0625rem;
}
.sub-top > header .page-history > ul > li + li:before {
  content: "/";
  display: block;
  position: absolute;
  left: 0px;
  left: 0rem;
  font-size: 14px;
  font-size: 0.875rem;
  color: #767676;
}
.sub-top > header .page-history > ul > li.current > a {
  color: #0373c6;
}
.sub-top > header .page-history > ul > li:last-child {
  padding-right: 5px;
  padding-right: 0.3125rem;
}
.sub-top > header .page-history > ul > li > a {
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  color: #000000;
}
.sub-top > header .info-wrap {
  display: flex;
  align-items: center;
  padding-top: 50px;
  padding-top: 3.125rem;
  padding-right: 100px;
  padding-right: 6.25rem;
  padding-bottom: 50px;
  padding-bottom: 3.125rem;
  padding-left: 100px;
  padding-left: 6.25rem;
  box-sizing: border-box;
  border-bottom: 1px solid #dddddd;
}
.sub-top > header .info-wrap > header {
  display: flex;
  align-items: center;
  padding-right: 30px;
  padding-right: 1.875rem;
}
.sub-top > header .info-wrap > header .text-wrap {
  margin-left: 20px;
  margin-left: 1.25rem;
}
.sub-top > header .info-wrap > header .text-wrap > p > strong {
  color: #0373c6;
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 700;
}
.sub-top > header .info-wrap > header .text-wrap > p > span {
  color: #767676;
  font-weight: 700;
}
.sub-top > header .info-wrap > header .text-wrap > p + p {
  margin-top: 10px;
  margin-top: 0.625rem;
}
.sub-top > header .info-wrap > footer {
  display: flex;
  margin-left: auto;
}
.sub-top .info-txt {
  font-size: 16px;
  font-size: 1rem;
}
.sub-top .reg-form {
  position: relative;
  padding-top: 30px;
  padding-top: 1.875rem;
  padding-bottom: 30px;
  padding-bottom: 1.875rem;
  /* padding-left: 50px;
  padding-left: 3.125rem; */
  padding-left: 17px;
  padding-left: 1.0625rem;
  border-bottom: 1px solid #dddddd;
}
.sub-top .reg-form article {
  display: flex;
  width: 100%;
  padding-top: 5px;
  padding-top: 0.3125rem;
  padding-right: 0px;
  padding-right: 0rem;
  padding-bottom: 5px;
  padding-bottom: 0.3125rem;
  padding-left: 0px;
  padding-left: 0rem;
}
.sub-top .reg-form article > header {
  display: flex;
  align-items: center;
  width: 25%;
  min-height: 37px;
  min-height: 2.3125rem;
}
.sub-top .reg-form article > header > i {
  margin-right: 10px;
  margin-right: 0.625rem;
}
.sub-top .reg-form article > header > span {
  position: relative;
  line-height: 16px;
  line-height: 1rem;
  font-weight: 500;
}
.sub-top .reg-form article > header > span.req {
  display: inline-block;
  width: 10px;
  width: 0.625rem;
  height: 10px;
  height: 0.625rem;
}
.sub-top .reg-form article > header > span.req:before {
  content: "*";
  display: inline-block;
  position: absolute;
  top: 0px;
  top: 0rem;
  left: 5px;
  left: 0.3125rem;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 16px;
  line-height: 1rem;
  color: #ff6868;
}

.leftcont {
  width: 30%;
}

.prs-middle-cont {
  width: 100%;
  height: 200px;
  display: flex;
}

.prs-middle-cont > .card {
  width: 50%;
  display: block;
}

.cardcont {
  width: 50%;
}

.prs-datebox {
  margin-left: 5px;
  margin-right: 5px;
}

.sub-top .reg-form article .cont {
  /* margin-left: 80px; */
  align-items: center;
  width: 68%;
  min-height: 37px;
  min-height: 2.3125rem;
}

.sub-top .reg-form article .cont .contline {
  margin-top: 10px;
  width: 100%;
  display: flex;
}
.sub-top .reg-form article .cont.full > * {
  min-width: initial !important;
  max-width: initial !important;
}
.sub-top .reg-form article .cont.vert {
  flex-direction: column;
  align-items: flex-start;
}
.sub-top .reg-form article .cont > * {
  flex: 1;
}
.sub-top .reg-form article .cont .inp + .inp {
  margin-left: 10px;
  margin-left: 0.625rem;
}
.sub-top .reg-form article .cont .alert-info {
  margin-top: 5px;
  margin-top: 0.3125rem;
  max-width: initial !important;
}
.sub-top .reg-form footer {
  display: flex;
  justify-content: space-between;
}
.sub-top .reg-form.insert article .cont > * {
  /* max-width: 32%; */
}
.sub-top .reg-form.insert article .cont .btn {
  max-width: initial;
  flex: none;
  min-width: 32%;
}
.sub-top .reg-form.info article .cont > * {
  min-width: 32%;
}
.sub-top .reg-form.info article .cont .btn {
  max-width: 32%;
  min-width: auto;
}
.sub-top .link-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 35px;
  padding-top: 2.1875rem;
  padding-bottom: 40px;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #dddddd;
}
.sub-top .link-wrap .item {
  width: 35%;
}
.sub-top .link-wrap .item > h3 {
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
}
.sub-top .link-wrap .item .item-type {
  display: flex;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
}
.sub-top .link-wrap .item .item-type > span {
  margin-left: 10px;
  margin-left: 0.625rem;
}
.sub-cont {
  position: relative;
  margin-top: 50px;
  margin-top: 3.125rem;
  padding-bottom: 50px;
  padding-bottom: 3.125rem;
  overflow: hidden;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.sub-cont > header {
  display: flex;
  padding-bottom: 20px;
  padding-bottom: 1.25rem;
}
.sub-cont > header.line {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}
.sub-cont > header > h2 {
  display: flex;
  align-items: center;
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 500;
  padding-bottom: 20px;
  padding-bottom: 1.25rem;
}
.sub-cont > header > h2 > span + span {
  margin-left: 10px;
  margin-left: 0.625rem;
}
.sub-cont > header .header-item {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.sub-cont > header .header-item.full {
  width: 100%;
  margin-left: initial;
  justify-content: space-between;
}
.sub-cont > header .header-item .icon-view {
  display: flex;
  margin-right: 30px;
  margin-right: 1.875rem;
}
.sub-cont > header .header-item .icon-view > li > i {
  font-size: 18px;
  font-size: 1.125rem;
  color: #0373c6;
  margin-right: 5px;
  margin-right: 0.3125rem;
}
.sub-cont > header .header-item .icon-view > li > span {
  font-size: 14px;
  font-size: 0.875rem;
  color: #767676;
}
.sub-cont > header .header-item .icon-view > li > span + span {
  margin-left: 5px;
  margin-left: 0.3125rem;
}
.sub-cont > header .header-item .icon-view > li + li {
  margin-left: 20px;
  margin-left: 1.25rem;
}
.sub-cont.search-mode > header {
  position: absolute;
  top: 0px;
  top: 0rem;
  padding-bottom: 0px;
  padding-bottom: 0rem;
  z-index: 1;
}
.sub-cont.search-mode > header > h2 {
  padding-bottom: 0px;
  padding-bottom: 0rem;
}
.sub-cont.search-mode > header .header-item {
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
  margin-left: 0.9375rem;
}
.sub-cont.search-mode > header .header-item .icon-view {
  margin-bottom: 0px;
  margin-bottom: 0rem;
}
.sub-cont__wrap {
  display: flex;
  justify-content: space-between;
}
.sub-cont__wrap > article {
  flex: 1;
  overflow-y: auto;
}
.sub-cont__wrap > article + article {
  margin-left: 50px;
  margin-left: 3.125rem;
}
.sub-cont__wrap > article > h3 {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}
.sub-cont__wrap > article > h3 > strong {
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 400;
  margin-right: 10px;
  margin-right: 0.625rem;
}
.sub-cont__wrap > article.cont-box {
  flex: none;
  margin-left: auto;
  margin-right: auto;
  width: 760px;
  width: 47.5rem;
  padding-top: 30px;
  padding-top: 1.875rem;
  padding-right: 30px;
  padding-right: 1.875rem;
  padding-bottom: 30px;
  padding-bottom: 1.875rem;
  padding-left: 30px;
  padding-left: 1.875rem;
  border: 1px solid #dddddd;
  box-sizing: border-box;
}
.sub-cont .search-wrap {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.sub-cont .search-wrap .inp {
  min-width: 200px;
  min-width: 12.5rem;
  margin-right: 10px;
  margin-right: 0.625rem;
}
.sub-cont .search-wrap .wrapper {
  display: flex;
}
.sub-cont .search-wrap .wrapper .hyphen {
  padding-left: 10px;
  padding-left: 0.625rem;
  padding-right: 10px;
  padding-right: 0.625rem;
}
.sub-cont .search-wrap .btn {
  flex: none;
}
.sub-cont > footer {
  display: flex;
  margin-top: 10px;
  margin-top: 0.625rem;
}
.icon-status {
  padding-top: 2px;
  padding-top: 0.125rem;
  padding-right: 10px;
  padding-right: 0.625rem;
  padding-bottom: 2px;
  padding-bottom: 0.125rem;
  padding-left: 10px;
  padding-left: 0.625rem;
  height: 16px;
  height: 1rem;
  color: #ffffff;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 14px;
  line-height: 0.875rem;
  border-radius: 16px;
  border-radius: 1rem;
  box-sizing: border-box;
}
.icon-status.success {
  background-color: #05a705;
}
.icon-status.fail {
  background-color: #ffa93c;
}
.icon-status.missed {
  background-color: #ff0000;
}
@media all and (max-width: 1240px) {
  .main-area {
    margin: 0;
    padding-left: 2%;
    padding-right: 2%;
  }
  .super-area {
    padding-left: 2%;
    padding-right: 2%;
  }
  .sub-top {
    padding-left: 2%;
    padding-right: 2%;
  }
  .sub-cont {
    padding-left: 2%;
    padding-right: 2%;
  }
}
@media all and (max-width: 1024px) {
  .super-area .super-cont .adm-wrap {
    flex-direction: column;
  }
  .super-area .super-cont .adm-wrap > article {
    width: 100% !important;
    margin-left: 0px !important;
    margin-left: 0rem !important;
    margin-top: 30px;
    margin-top: 1.875rem;
  }
  .sub-top .reg-form {
    padding-left: 0px;
    padding-left: 0rem;
  }
  .sub-top .reg-form article {
    width: 100%;
  }
  .sub-cont > header .header-item {
    flex-direction: column;
    align-items: flex-end;
  }
  .sub-cont > header .header-item .icon-view {
    margin-right: 0px;
    margin-right: 0rem;
    margin-bottom: 10px;
    margin-bottom: 0.625rem;
  }
}
@media all and (max-width: 768px) {
  .main-area {
    padding-left: 3%;
    padding-right: 3%;
  }
  .main-area .item {
    width: 100%;
  }
  .super-area {
    padding-left: 3%;
    padding-right: 3%;
  }
  .sub-top {
    flex-direction: column;
    margin-top: 0px;
    margin-top: 0rem;
    padding-left: 3%;
    padding-right: 3%;
  }
  .sub-top > header {
    width: auto;
    min-width: auto;
    display: flex;
  }
  .sub-top > header h2 {
    width: 100%;
  }
  .sub-top > header .info-wrap {
    width: 100%;
    padding-top: 20px;
    padding-top: 1.25rem;
    padding-right: 0px;
    padding-right: 0rem;
    padding-bottom: 20px;
    padding-bottom: 1.25rem;
    padding-left: 0px;
    padding-left: 0rem;
  }
  .sub-top > header .page-history {
    padding-right: 0px;
    padding-right: 0rem;
  }
  .sub-top .reg-form {
    width: 100%;
  }
  .sub-top .reg-form article {
    flex-direction: column;
  }
  .sub-top .reg-form article > header {
    width: 100%;
  }
  .sub-top .reg-form article .cont {
    width: 100%;
  }
  .sub-top .reg-form footer {
    margin-top: 20px;
    margin-top: 1.25rem;
  }
  .sub-top .reg-form footer .btn {
    flex: 1;
  }
  .sub-top .reg-form.insert article .cont > *,
  .sub-top .reg-form.info article .cont > *,
  .sub-top .reg-form.insert article .cont .btn,
  .sub-top .reg-form.info article .cont .btn {
    max-width: initial;
  }
  .sub-cont {
    margin-top: 50px;
    margin-top: 3.125rem;
    padding-left: 3%;
    padding-right: 3%;
  }
  .sub-cont > header {
    flex-direction: column;
  }
  .sub-cont > header.line {
    border: none;
    margin-bottom: 0px;
    margin-bottom: 0rem;
  }
  .sub-cont.search-mode {
    padding-top: 70px;
    padding-top: 4.375rem;
  }
  .sub-cont.search-mode > header .header-item {
    margin-top: 5px;
    margin-top: 0.3125rem;
    margin-left: 0px;
    margin-left: 0rem;
  }
  .sub-cont__wrap {
    padding-top: 0px;
    padding-top: 0rem;
    flex-direction: column;
  }
  .sub-cont__wrap > article + article {
    margin-left: 0px;
    margin-left: 0rem;
    margin-top: 30px;
    margin-top: 1.875rem;
  }
  .sub-cont__wrap > article.cont-box {
    width: 94%;
  }
}
@media all and (max-width: 480px) {
  .login {
    padding-right: 3%;
    padding-left: 3%;
    width: 100%;
  }
  .login > header {
    margin-bottom: 5px;
    margin-bottom: 0.3125rem;
  }
  .login > header > h1 {
    font-size: 28px;
    font-size: 1.75rem;
  }
  .login__form > h2 {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .main-area .grid-icon > i {
    font-size: 100px;
    font-size: 6.25rem;
  }
  .main-area dl dt > strong {
    font-size: 18px;
    font-size: 1.125rem;
  }
  .main-area dl dt > span {
    font-size: 16px;
    font-size: 1rem;
  }
  .main-area dl dd {
    flex-direction: column;
  }
  .main-area dl dd > .grid-bar {
    display: none;
  }
  .main-area .item {
    border-bottom: 1px solid #dddddd;
  }
  .main-area .item.lst {
    border-bottom: none;
  }
  .super-area .super-cont .total-item {
    padding-bottom: 0px;
    padding-bottom: 0rem;
    flex-direction: column;
  }
  .super-area .super-cont .total-item .item {
    padding-bottom: 20px;
    padding-bottom: 1.25rem;
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
    border-bottom: 1px solid #dddddd;
  }
  .super-area .super-cont .total-item .item:last-child {
    border: none;
  }
  .sub-top > header .page-history > ul > li > a {
    font-size: 12px;
    font-size: 0.75rem;
  }
  .sub-top > header .info-wrap {
    flex-direction: column;
    align-items: flex-start;
  }
  .sub-top > header .info-wrap > header .text-wrap > p > strong,
  .sub-top > header .info-wrap > header .text-wrap > p > span {
    display: block;
  }
  .sub-top > header .info-wrap > footer {
    margin-top: 20px;
    margin-top: 1.25rem;
  }
  .sub-top .info-txt {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .sub-top .info-txt > span {
    display: block;
  }
  .sub-top .reg-form article .cont .img-wrap > img {
    width: 100%;
  }
  .sub-top .link-wrap {
    flex-direction: column;
  }
  .sub-top .link-wrap .item {
    width: 100%;
  }
  .sub-top .link-wrap .btn {
    margin-top: 20px;
    margin-top: 1.25rem;
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
  }
  .sub-cont__wrap > article.cont-box {
    width: 100%;
    padding-left: 0px;
    padding-left: 0rem;
    padding-right: 0px;
    padding-right: 0rem;
    border-left: none;
    border-right: none;
  }
  .sub-cont .search-wrap .btn i {
    display: none;
  }
}
input[type="tel"],
input[type="text"],
input[type="email"],
input[type="date"],
input[type="number"],
input[type="password"],
input[type="submit"],
input[type="button"],
input[type="reset"],
textarea {
  font-weight: inherit;
  border-radius: unset;
  background-color: #ffffff;
  height: 37px;
  height: 2.3125rem;
  line-height: 35px;
  line-height: 2.1875rem;
}
input[type="tel"]::placeholder,
input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="date"]::placeholder,
input[type="number"]::placeholder,
input[type="password"]::placeholder,
input[type="submit"]::placeholder,
input[type="button"]::placeholder,
input[type="reset"]::placeholder,
textarea::placeholder {
  color: #a2a2a2;
}
input[type="tel"]:-ms-clear,
input[type="text"]:-ms-clear,
input[type="email"]:-ms-clear,
input[type="date"]:-ms-clear,
input[type="number"]:-ms-clear,
input[type="password"]:-ms-clear,
input[type="submit"]:-ms-clear,
input[type="button"]:-ms-clear,
input[type="reset"]:-ms-clear,
textarea:-ms-clear {
  display: none;
}
input[type="tel"]:read-only,
input[type="text"]:read-only,
input[type="email"]:read-only,
input[type="date"]:read-only,
input[type="number"]:read-only,
input[type="password"]:read-only,
input[type="submit"]:read-only,
input[type="button"]:read-only,
input[type="reset"]:read-only,
textarea:read-only {
  background: #f5f5f5;
  border: 1px solid #dddddd;
}
input[type="tel"]:read-only:focus,
input[type="text"]:read-only:focus,
input[type="email"]:read-only:focus,
input[type="date"]:read-only:focus,
input[type="number"]:read-only:focus,
input[type="password"]:read-only:focus,
input[type="submit"]:read-only:focus,
input[type="button"]:read-only:focus,
input[type="reset"]:read-only:focus,
textarea:read-only:focus,
input[type="tel"]:read-only:active,
input[type="text"]:read-only:active,
input[type="email"]:read-only:active,
input[type="date"]:read-only:active,
input[type="number"]:read-only:active,
input[type="password"]:read-only:active,
input[type="submit"]:read-only:active,
input[type="button"]:read-only:active,
input[type="reset"]:read-only:active,
textarea:read-only:active {
  border: 1px solid #dddddd;
}
input[type="tel"]:disabled,
input[type="text"]:disabled,
input[type="email"]:disabled,
input[type="date"]:disabled,
input[type="number"]:disabled,
input[type="password"]:disabled,
input[type="submit"]:disabled,
input[type="button"]:disabled,
input[type="reset"]:disabled,
textarea:disabled {
  color: #767676;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
input[type="text"],
input[type="password"],
textarea {
  display: block;
  width: 100%;
  padding-top: 8px;
  padding-top: 0.5rem;
  padding-right: 10px;
  padding-right: 0.625rem;
  padding-bottom: 8px;
  padding-bottom: 0.5rem;
  padding-left: 10px;
  padding-left: 0.625rem;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: inherit;
  border: 1px solid #dddddd;
  box-sizing: border-box;
}
input[type="text"]:focus,
input[type="password"]:focus,
textarea:focus {
  outline: none;
  border: 1px solid #000000;
}
textarea {
  padding-top: 10px;
  padding-top: 0.625rem;
  padding-right: 10px;
  padding-right: 0.625rem;
  padding-bottom: 10px;
  padding-bottom: 0.625rem;
  padding-left: 10px;
  padding-left: 0.625rem;
}
input[type="radio"] {
  position: absolute;
  width: 1px;
  width: 0.0625rem;
  height: 1px;
  height: 0.0625rem;
  padding-top: 0px;
  padding-top: 0rem;
  padding-right: 0px;
  padding-right: 0rem;
  padding-bottom: 0px;
  padding-bottom: 0rem;
  padding-left: 0px;
  padding-left: 0rem;
  margin-top: -1px;
  margin-top: -0.0625rem;
  margin-right: -1px;
  margin-right: -0.0625rem;
  margin-bottom: -1px;
  margin-bottom: -0.0625rem;
  margin-left: -1px;
  margin-left: -0.0625rem;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
input[type="radio"] + label {
  position: relative;
  padding-left: 23px;
  padding-left: 1.4375rem;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  cursor: pointer;
  word-break: break-word;
}
input[type="radio"] + label:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  transform: translateY(-48%);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
input[type="radio"] + label:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #ffffff;
  transform: translateY(-45%);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
input[type="radio"]:checked + label:before {
  border-color: #0373c6;
  background-color: #0373c6;
}
input[type="checkbox"] {
  position: absolute;
  width: 1px;
  width: 0.0625rem;
  height: 1px;
  height: 0.0625rem;
  padding-top: 0px;
  padding-top: 0rem;
  padding-right: 0px;
  padding-right: 0rem;
  padding-bottom: 0px;
  padding-bottom: 0rem;
  padding-left: 0px;
  padding-left: 0rem;
  margin-top: -1px;
  margin-top: -0.0625rem;
  margin-right: -1px;
  margin-right: -0.0625rem;
  margin-bottom: -1px;
  margin-bottom: -0.0625rem;
  margin-left: -1px;
  margin-left: -0.0625rem;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
input[type="checkbox"] + label {
  position: relative;
  padding-left: 28px;
  padding-left: 1.75rem;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  cursor: pointer;
  word-break: break-word;
}
input[type="checkbox"] + label:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  transform: translateY(-48%);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
input[type="checkbox"]:checked + label:before {
  border-color: #0373c6;
  background-color: #0373c6;
}
input[type="checkbox"]:checked + label:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 7px;
  width: 5px;
  height: 9px;
  border-right: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  transform: translateY(-65%) rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.tf label,
.inp label {
  display: inline-block;
}
.tf.type,
.inp.type {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tf.type .hyphen,
.inp.type .hyphen {
  margin-top: 0px;
  margin-top: 0rem;
  margin-right: 10px;
  margin-right: 0.625rem;
  margin-bottom: 0px;
  margin-bottom: 0rem;
  margin-left: 10px;
  margin-left: 0.625rem;
}
.tf.type--02 > span,
.inp.type--02 > span {
  width: 49%;
}
.tf.type--03 .insert,
.inp.type--03 .insert {
  width: 30%;
}
.tf.no-bor input,
.inp.no-bor input {
  width: 55px;
  width: 3.4375rem;
  padding-top: 5px;
  padding-top: 0.3125rem;
  padding-right: 10px;
  padding-right: 0.625rem;
  padding-bottom: 5px;
  padding-bottom: 0.3125rem;
  padding-left: 10px;
  padding-left: 0.625rem;
  border: none;
  border-bottom: 1px solid #0373c6;
}
.opt {
  position: relative;
  flex: none;
}
.opt:before {
  content: "";
  display: block;
  position: absolute;
  width: 7px;
  width: 0.4375rem;
  height: 7px;
  height: 0.4375rem;
  top: 11px;
  top: 0.6875rem;
  right: 15px;
  right: 0.9375rem;
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
  transform: rotate(45deg);
  z-index: 2;
}
.opt.full {
  width: 100%;
}
.opt select {
  display: block;
  width: 100%;
  height: 37px;
  height: 2.3125rem;
  padding-left: 15px;
  padding-left: 0.9375rem;
  padding-right: 40px;
  padding-right: 2.5rem;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: inherit;
  line-height: inherit;
  border-radius: 0px;
  border-radius: 0rem;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  cursor: pointer;
}
.opt select:focus {
  outline: none;
  border: 1px solid #000000;
}
.opt.sm:before {
  top: 9px;
  top: 0.5625rem;
}
.opt.sm > select {
  height: 31px;
  height: 1.9375rem;
  font-size: 13px;
  font-size: 0.8125rem;
}
.form-wrap {
  display: flex;
  align-items: center;
}
.form-wrap > span.w35 {
  width: 35%;
}
.form-wrap > div {
  flex: 1;
}
.form-wrap .btn {
  flex: none;
}
@media all and (max-width: 1024px) {
  .calendar-view .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
  .calendar-view .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
    top: 10px;
    top: 0.625rem;
    padding-top: 10px;
    padding-top: 0.625rem;
    padding-right: 10px;
    padding-right: 0.625rem;
    padding-bottom: 10px;
    padding-bottom: 0.625rem;
    padding-left: 10px;
    padding-left: 0.625rem;
  }
  .calendar-view .ui-datepicker .ui-datepicker-header .ui-datepicker-title {
    font-size: 30px;
    font-size: 1.875rem;
  }
  .calendar-view .ui-datepicker .ui-datepicker-calendar td span,
  .calendar-view .ui-datepicker .ui-datepicker-calendar td a {
    min-height: 60px;
    min-height: 3.75rem;
  }
}
@media all and (max-width: 768px) {
  .calendar-view .ui-datepicker .ui-datepicker-header {
    margin-top: 0px;
    margin-top: 0rem;
    margin-bottom: 10px;
    margin-bottom: 0.625rem;
  }
  .calendar-view .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
  .calendar-view .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
    font-size: 30px;
    font-size: 1.875rem;
    top: 5px;
    top: 0.3125rem;
    padding-top: 10px;
    padding-top: 0.625rem;
    padding-right: 10px;
    padding-right: 0.625rem;
    padding-bottom: 10px;
    padding-bottom: 0.625rem;
    padding-left: 10px;
    padding-left: 0.625rem;
  }
  .calendar-view .ui-datepicker .ui-datepicker-header .ui-datepicker-title {
    font-size: 24px;
    font-size: 1.5rem;
  }
  .calendar-view .ui-datepicker .ui-datepicker-calendar th span {
    font-size: 18px;
    font-size: 1.125rem;
  }
  .calendar-view .ui-datepicker .ui-datepicker-calendar td {
    padding-top: 2px;
    padding-top: 0.125rem;
    padding-right: 2px;
    padding-right: 0.125rem;
    padding-bottom: 2px;
    padding-bottom: 0.125rem;
    padding-left: 2px;
    padding-left: 0.125rem;
  }
  .calendar-view .ui-datepicker .ui-datepicker-calendar td span,
  .calendar-view .ui-datepicker .ui-datepicker-calendar td a {
    font-size: 15px;
    font-size: 0.9375rem;
    min-height: 30px;
    min-height: 1.875rem;
  }
}
@media all and (max-width: 480px) {
  .tf.type--02,
  .inp.type--02 {
    flex-direction: column;
  }
  .tf.type--02 > span,
  .inp.type--02 > span {
    width: 100%;
  }
  .tf.type--02 > span + span,
  .inp.type--02 > span + span {
    margin-top: 7px;
    margin-top: 0.4375rem;
  }
}
table tr th,
table tr td {
  position: relative;
  padding-top: 7px;
  padding-top: 0.4375rem;
  padding-right: 10px;
  padding-right: 0.625rem;
  padding-bottom: 7px;
  padding-bottom: 0.4375rem;
  padding-left: 10px;
  padding-left: 0.625rem;
  font-size: 14px;
  font-size: 0.875rem;
  color: #000000;
  font-weight: inherit;
  text-align: left;
  border: 1px solid #dddddd;
  box-sizing: border-box;
}
table tr th > strong,
table tr td > strong {
  color: #000000;
  font-weight: 500;
}
table tr th > strong.req,
table tr td > strong.req {
  position: relative;
}
table tr th > strong.req:after,
table tr td > strong.req:after {
  content: "*";
  display: block;
  position: absolute;
  top: 0px;
  top: 0rem;
  right: -10px;
  right: -0.625rem;
  color: #ff6868;
}
table tr th .link,
table tr td .link {
  color: #0373c6;
  text-decoration: underline;
}
table tr th .icon > i,
table tr td .icon > i {
  color: #0373c6;
}
table thead {
  background-color: rgba(0, 0, 0, 0.02);
}
table thead th {
  font-weight: 500;
}
table tbody tr th {
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.02);
}
table .no-data {
  height: 150px;
  height: 9.375rem;
  box-sizing: border-box;
}
table .msg {
  width: 40%;
}
table .msg > span {
  display: block;
  width: 100%;
}
.tbl-wrap {
  flex: 1;
  overflow-x: auto;
  background-color: #ffffff;
}
.tbl-wrap table.type-sm th,
.tbl-wrap table.type-sm td {
  padding-top: 5px !important;
  padding-top: 0.3125rem !important;
  padding-right: 5px !important;
  padding-right: 0.3125rem !important;
  padding-bottom: 5px !important;
  padding-bottom: 0.3125rem !important;
  padding-left: 5px !important;
  padding-left: 0.3125rem !important;
}
.tbl-wrap table.type-ct th,
.tbl-wrap table.type-ct td {
  text-align: center !important;
}
.tbl-wrap table.type-th th {
  text-align: center !important;
}
.tbl-wrap table.type-pop thead th > strong {
  font-size: 15px;
  font-size: 0.9375rem;
  color: #0373c6;
}
.tbl-wrap table.type-pop tbody td {
  font-weight: 300;
}
.tbl-wrap table.type-pop tbody td span,
.tbl-wrap table.type-pop tbody td label {
  font-weight: 300;
}
.tbl-wrap table.type-bor th:first-child,
.tbl-wrap table.type-bor td:first-child {
  border-left: 1px solid #dddddd;
}
.tbl-wrap table.type-bor th:last-child,
.tbl-wrap table.type-bor td:last-child {
  border-right: 1px solid #dddddd;
}
.tbl-wrap table.type-line th,
.tbl-wrap table.type-line td {
  border: none;
  border-bottom: 1px solid #dddddd;
  background-color: transparent;
}
.tbl-wrap table.type-line tbody tr:last-child th,
.tbl-wrap table.type-line tbody tr:last-child td {
  border-bottom: none;
}
.tbl-wrap table.type-color th,
.tbl-wrap table.type-color td {
  text-align: center;
}
.tbl-wrap table.type-color th > span,
.tbl-wrap table.type-color td > span {
  font-size: 12px;
  font-size: 0.75rem;
}
.tbl-wrap table.type-color th {
  width: 80px;
  width: 5rem;
}
.tbl-wrap table.type-color th.lv1 {
  color: #ffffff;
  background-color: green;
}
.tbl-wrap table.type-color th.lv2 {
  color: #000000;
  background-color: yellow;
}
.tbl-wrap table.type-color th.lv3 {
  color: #000000;
  background-color: orange;
}
.tbl-wrap table.type-color th.lv4 {
  color: #000000;
  background-color: red;
}
.tbl-wrap table.type-color th.slv0 {
  color: #ffffff;
  background-color: green;
}
.tbl-wrap table.type-color th.slv1 {
  color: #ffffff;
  background-color: #0373c6;
}
.tbl-wrap table.type-color th.slv2 {
  color: #ffffff;
  background-color: #0373c6;
}
.tbl-wrap table.type-color th.slv3 {
  color: #ffffff;
  background-color: #0373c6;
}
.tbl-wrap table.type-color th.slv4 {
  color: #ffffff;
  background-color: #0373c6;
}
.tbl-wrap table.type-color td.icon {
  width: 35px;
  width: 2.1875rem;
}
@media all and (max-width: 768px) {
  table tr th,
  table tr td {
    font-size: 13px;
    font-size: 0.8125rem;
  }
  table .msg > span {
    min-width: 200px;
    min-width: 12.5rem;
  }
}
.btn {
  position: relative;
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  color: #000000;
  border-radius: 0px !important;
  border-radius: 0rem !important;
  padding-top: 0px;
  padding-top: 0rem;
  padding-right: 0px;
  padding-right: 0rem;
  padding-bottom: 0px;
  padding-bottom: 0rem;
  padding-left: 0px;
  padding-left: 0rem;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.btn > span,
.btn > div,
.btn > strong {
  position: relative;
  vertical-align: initial;
  padding-top: 0px !important;
  padding-top: 0rem !important;
  padding-right: 0px !important;
  padding-right: 0rem !important;
  padding-bottom: 0px !important;
  padding-bottom: 0rem !important;
  padding-left: 0px !important;
  padding-left: 0rem !important;
  z-index: 2;
}
.btn .icon {
  font-size: 16px;
  font-size: 1rem;
  z-index: 2;
}
.btn.xsm {
  padding-top: 2px;
  padding-top: 0.125rem;
  padding-right: 10px;
  padding-right: 0.625rem;
  padding-bottom: 2px;
  padding-bottom: 0.125rem;
  padding-left: 10px;
  padding-left: 0.625rem;
  height: 24px;
  height: 1.5rem;
  font-size: 12px;
  font-size: 0.75rem;
}
.btn.xsm > span,
.btn.xsm > strong {
  line-height: 17px;
  line-height: 1.0625rem;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: inherit;
}
.btn.xsm > strong {
  font-weight: 500;
}
.btn.xsm.square {
  padding-top: 2px;
  padding-top: 0.125rem;
  padding-right: 7px;
  padding-right: 0.4375rem;
  padding-bottom: 2px;
  padding-bottom: 0.125rem;
  padding-left: 7px;
  padding-left: 0.4375rem;
}
.btn.sm {
  padding-top: 2px;
  padding-top: 0.125rem;
  padding-right: 10px;
  padding-right: 0.625rem;
  padding-bottom: 2px;
  padding-bottom: 0.125rem;
  padding-left: 10px;
  padding-left: 0.625rem;
  height: 31px;
  height: 1.9375rem;
  font-size: 12px;
  font-size: 0.75rem;
}
.btn.sm > span,
.btn.sm > strong {
  line-height: 24px;
  line-height: 1.5rem;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: inherit;
}
.btn.sm > strong {
  font-weight: 500;
}
.btn.md {
  padding-top: 5px;
  padding-top: 0.3125rem;
  padding-right: 10px;
  padding-right: 0.625rem;
  padding-bottom: 5px;
  padding-bottom: 0.3125rem;
  padding-left: 10px;
  padding-left: 0.625rem;
  min-width: 60px;
  min-width: 3.75rem;
  height: 37px;
  height: 2.3125rem;
  font-size: 15px;
  font-size: 0.9375rem;
}
.btn.md > span,
.btn.md > strong {
  line-height: 20px;
  line-height: 1.25rem;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 500;
}
.btn.md > strong {
  font-weight: 500;
}
.btn.mlg {
  padding-top: 10px;
  padding-top: 0.625rem;
  padding-right: 10px;
  padding-right: 0.625rem;
  padding-bottom: 10px;
  padding-bottom: 0.625rem;
  padding-left: 10px;
  padding-left: 0.625rem;
  min-width: 180px;
  min-width: 11.25rem;
  font-size: 18px;
  font-size: 1.125rem;
}
.btn.mlg > span,
.btn.mlg > strong {
  line-height: 23px;
  line-height: 1.4375rem;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 500;
}
.btn.mlg > strong {
  font-weight: 500;
}
.btn.lg {
  padding-top: 15px;
  padding-top: 0.9375rem;
  padding-right: 15px;
  padding-right: 0.9375rem;
  padding-bottom: 15px;
  padding-bottom: 0.9375rem;
  padding-left: 15px;
  padding-left: 0.9375rem;
  min-width: 150px;
  min-width: 9.375rem;
  font-size: 20px;
  font-size: 1.25rem;
}
.btn.lg > span,
.btn.lg > strong {
  line-height: 25px;
  line-height: 1.5625rem;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 500;
}
.btn.lg > strong {
  font-weight: 500;
}
.btn.black {
  color: #ffffff;
  background-color: #000000;
  border: 1px solid #000000;
}
.btn.black .icon:before,
.btn.black .icon:after {
  border-color: #ffffff;
}
.btn.black:hover .icon,
.btn.black:focus .icon {
  color: #000000;
}
.btn.black:hover .icon:before,
.btn.black:focus .icon:before,
.btn.black:hover .icon:after,
.btn.black:focus .icon:after {
  border-color: #000000;
}
.btn.blue {
  color: #ffffff;
  background-color: #0373c6;
  border: 1px solid #0373c6;
}
.btn.blue .icon:before,
.btn.blue .icon:after {
  border-color: #ffffff;
}
.btn.blue:hover .icon,
.btn.blue:focus .icon {
  color: #0373c6;
}
.btn.blue:hover .icon:before,
.btn.blue:focus .icon:before,
.btn.blue:hover .icon:after,
.btn.blue:focus .icon:after {
  border-color: #0373c6;
}
.btn.white {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #000000;
}
.btn.white .icon:before,
.btn.white .icon:after {
  border-color: #000000;
}
.btn.white:hover .icon,
.btn.white:focus .icon {
  color: #ffffff;
}
.btn.white:hover .icon:before,
.btn.white:focus .icon:before,
.btn.white:hover .icon:after,
.btn.white:focus .icon:after {
  border-color: #ffffff;
}
.btn.red {
  color: #ffffff;
  background-color: #ff6868;
  border: 1px solid #ff6868;
}
.btn.red .icon:before,
.btn.red .icon:after {
  border-color: #ffffff;
}
.btn.red:hover .icon,
.btn.red:focus .icon {
  color: #ff6868;
}
.btn.red:hover .icon:before,
.btn.red:focus .icon:before,
.btn.red:hover .icon:after,
.btn.red:focus .icon:after {
  border-color: #ff6868;
}
.btn.grey {
  color: #ffffff;
  background-color: #767676;
  border: 1px solid #767676;
}
.btn.grey .icon:before,
.btn.grey .icon:after {
  border-color: #ffffff;
}
.btn.grey:hover .icon,
.btn.grey:focus .icon {
  color: #767676;
}
.btn.grey:hover .icon:before,
.btn.grey:focus .icon:before,
.btn.grey:hover .icon:after,
.btn.grey:focus .icon:after {
  border-color: #767676;
}
.btn.yellow {
  color: #ffffff;
  background-color: #ffa93c;
  border: 1px solid #ffa93c;
}
.btn.yellow .icon:before,
.btn.yellow .icon:after {
  border-color: #ffffff;
}
.btn.yellow:hover .icon,
.btn.yellow:focus .icon {
  color: #ffa93c;
}
.btn.yellow:hover .icon:before,
.btn.yellow:focus .icon:before,
.btn.yellow:hover .icon:after,
.btn.yellow:focus .icon:after {
  border-color: #ffa93c;
}
.btn.full {
  width: 100%;
}
.btn:disabled {
  cursor: default;
  pointer-events: none;
  opacity: 0.3;
}
.btn:disabled:hover:before {
  display: none;
}
.btn:disabled:hover:after {
  display: none;
}
.btn-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-wrap .btn + .btn {
  margin-left: 10px;
  margin-left: 0.625rem;
}
.icon {
  position: relative;
}
.icon--close:before {
  content: "";
  display: block;
  position: absolute;
  width: 11px;
  width: 0.6875rem;
  height: 1px;
  height: 0.0625rem;
  top: 8px;
  top: 0.5rem;
  left: 3px !important;
  left: 0.1875rem !important;
  transform: rotate(45deg);
  background-color: #000000;
}
.icon--close:after {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  width: 0.0625rem;
  height: 11px;
  height: 0.6875rem;
  top: 3px;
  top: 0.1875rem;
  left: 8px;
  left: 0.5rem;
  transform: rotate(45deg);
  background-color: #000000;
}
.icon--close.sm {
  display: inline-block;
  width: 16px;
  width: 1rem;
  height: 16px;
  height: 1rem;
  vertical-align: text-bottom;
  cursor: pointer;
  box-sizing: border-box;
  z-index: 2;
}
.icon--close.lg {
  position: absolute;
  display: inline-block;
  top: 15px;
  top: 0.9375rem;
  right: 15px;
  right: 0.9375rem;
  width: 30px;
  width: 1.875rem;
  height: 30px;
  height: 1.875rem;
  z-index: 2;
  cursor: pointer;
  box-sizing: border-box;
}
.icon--close.lg:before {
  width: 27px;
  width: 1.6875rem;
  height: 1px;
  height: 0.0625rem;
  top: 15px;
  top: 0.9375rem;
  left: 2px !important;
  left: 0.125rem !important;
  transform: rotate(45deg);
  background-color: #ffffff;
}
.icon--close.lg:after {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  width: 0.0625rem;
  height: 27px;
  height: 1.6875rem;
  top: 2px;
  top: 0.125rem;
  left: 15px;
  left: 0.9375rem;
  transform: rotate(45deg);
  background-color: #ffffff;
}
.badge.sm {
  font-size: 14px;
  font-size: 0.875rem;
  padding-top: 3px;
  padding-top: 0.1875rem;
  padding-right: 7px;
  padding-right: 0.4375rem;
  padding-bottom: 3px;
  padding-bottom: 0.1875rem;
  padding-left: 7px;
  padding-left: 0.4375rem;
  border-radius: 6px;
  border-radius: 0.375rem;
}
.badge.lg {
  font-size: 18px;
  font-size: 1.125rem;
  padding-top: 12px;
  padding-top: 0.75rem;
  padding-right: 18px;
  padding-right: 1.125rem;
  padding-bottom: 12px;
  padding-bottom: 0.75rem;
  padding-left: 18px;
  padding-left: 1.125rem;
  border-radius: 6px;
  border-radius: 0.375rem;
}
.badge.blue-bg {
  color: #ffffff !important;
  background-color: #0373c6 !important;
}
.badge.dark-bg {
  color: #ffffff !important;
  background-color: #767676 !important;
}
.num-ea {
  display: inline-block;
  padding-top: 2px;
  padding-top: 0.125rem;
  padding-right: 10px;
  padding-right: 0.625rem;
  padding-bottom: 2px;
  padding-bottom: 0.125rem;
  padding-left: 10px;
  padding-left: 0.625rem;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 20px;
  line-height: 1.25rem;
  border-radius: 20px;
  border-radius: 1.25rem;
  color: #ffffff;
  background-color: #0373c6;
  box-sizing: border-box;
}
html.full {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  position: fixed;
}
html.full > body {
  overflow-y: auto;
}
.dimmed {
  display: none;
  position: fixed;
  left: 0px;
  left: 0rem;
  top: 0px;
  top: 0rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #000000;
  opacity: 0.5;
  z-index: 101;
}
.pop-layer {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100%;
  font-size: 14px;
  font-size: 0.875rem;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 102;
  box-sizing: border-box;
  overflow-y: auto;
}
.pop-layer__wrap {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  min-width: 20rem;
}
.pop-layer header {
  position: relative;
  padding-top: 15px;
  padding-top: 0.9375rem;
  padding-right: 20px;
  padding-right: 1.25rem;
  padding-bottom: 15px;
  padding-bottom: 0.9375rem;
  padding-left: 20px;
  padding-left: 1.25rem;
  text-align: center;
  background-color: #0373c6;
  border-bottom: 1px solid #dddddd;
}
.pop-layer header h3 {
  font-size: 20px;
  font-size: 1.25rem;
  color: #ffffff;
  text-align: left;
}
.pop-layer header h3 strong {
  font-weight: 500;
}
.pop-layer .cont {
  padding-top: 20px;
  padding-top: 1.25rem;
  padding-right: 20px;
  padding-right: 1.25rem;
  padding-bottom: 20px;
  padding-bottom: 1.25rem;
  padding-left: 20px;
  padding-left: 1.25rem;
  min-height: 100px;
  min-height: 6.25rem;
}
.pop-layer .cont__wrap {
  overflow-x: auto;
}
.pop-layer .cont__wrap h4 {
  position: relative;
  font-size: 16px;
  font-size: 1rem;
  padding-bottom: 5px;
  padding-bottom: 0.3125rem;
}
.pop-layer .cont__wrap h4 strong {
  color: #0373c6;
  font-weight: 500;
}
.pop-layer .cont__wrap h4 span {
  font-size: 14px;
  font-size: 0.875rem;
  color: #767676;
}
.pop-layer .cont__wrap h4 i {
  color: #0373c6;
}
.pop-layer .cont__wrap article .level-wrap {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-top: 0.625rem;
  padding-right: 10px;
  padding-right: 0.625rem;
  padding-bottom: 10px;
  padding-bottom: 0.625rem;
  padding-left: 10px;
  padding-left: 0.625rem;
  border: 1px solid #dddddd;
}
.pop-layer .cont__wrap article .level-wrap .block {
  width: 46%;
  margin: 2%;
  margin-top: 10px;
  margin-top: 0.625rem;
  margin-right: 10px;
  margin-right: 0.625rem;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
  margin-left: 10px;
  margin-left: 0.625rem;
}
.pop-layer .cont__wrap article .level-wrap .block > strong {
  color: #0373c6;
  font-weight: 500;
}
.pop-layer .cont__wrap article .level-wrap .block .record-wrap {
  display: flex;
  align-items: center;
}
.pop-layer .cont__wrap article .level-wrap .block .record-wrap .record > li {
  display: flex;
  align-items: center;
  height: 37px;
  height: 2.3125rem;
}
.pop-layer
  .cont__wrap
  article
  .level-wrap
  .block
  .record-wrap
  .record
  > li
  > span.txt {
  min-width: 60px;
  min-width: 3.75rem;
}
.pop-layer
  .cont__wrap
  article
  .level-wrap
  .block
  .record-wrap
  .record
  > li
  > span.sign {
  text-align: center;
  min-width: 40px;
  min-width: 2.5rem;
}
.pop-layer .cont__wrap article .level-wrap .block .record-wrap .unit .opt {
  min-width: 70px;
  min-width: 4.375rem;
  margin-left: 15px;
  margin-left: 0.9375rem;
}
.pop-layer .cont__wrap article .level-wrap .block .record-wrap .unit > span {
  margin-left: 20px;
  margin-left: 1.25rem;
}
.pop-layer .cont__wrap .txt-info {
  font-size: 15px;
  font-size: 0.9375rem;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
}
.pop-layer .cont__wrap .txt-info > strong {
  font-weight: 700;
}
.pop-layer .cont__wrap .txt-info > span {
  font-weight: 400;
}
.pop-layer .cont__wrap .update {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  padding-bottom: 0.625rem;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
  border-bottom: 1px solid #dddddd;
}
.pop-layer .cont__wrap .update .info {
  color: #0373c6;
}
.pop-layer footer {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-top: 1.25rem;
  padding-right: 20px;
  padding-right: 1.25rem;
  padding-bottom: 20px;
  padding-bottom: 1.25rem;
  padding-left: 20px;
  padding-left: 1.25rem;
  border-top: 1px solid #dddddd;
}
.pop-layer footer .btn-wrap {
  justify-content: flex-end;
}
.pop-layer footer .btn-wrap .btn {
  width: auto;
}
.pop-layer footer .btn-wrap .btn.md {
  min-width: 70px;
  min-width: 4.375rem;
}
.pop-layer--sm {
  width: 360px;
  width: 22.5rem;
}
.pop-layer--md {
  width: 500px;
  width: 31.25rem;
}
.pop-layer--lg {
  width: 650px;
  width: 40.625rem;
}
@media all and (max-width: 650px) {
  .pop-layer--lg {
    width: 100%;
  }
}
@media all and (max-width: 500px) {
  .pop-layer--md {
    width: 100%;
  }
}
@media all and (max-width: 360px) {
  .pop-layer--sm {
    width: 100%;
  }
}
.dx-widget .dx-placeholder {
  color: #a2a2a2;
}
.dx-widget.dx-editor-outlined {
  border: none !important;
}
.dx-widget .dx-datagrid .dx-datagrid-headers {
  border-bottom: none;
  background-color: #f1fcff;
}
.dx-widget
  .dx-datagrid
  .dx-datagrid-headers
  .dx-datagrid-content
  > table
  .dx-row
  > td {
  padding-top: 10px;
  padding-top: 0.625rem;
  padding-bottom: 10px;
  padding-bottom: 0.625rem;
  font-weight: 500;
  border-bottom: none;
  background-color: #f5f5f5;
}
.dx-widget
  .dx-datagrid
  .dx-datagrid-rowsview
  .dx-datagrid-content
  > table
  .dx-row
  + .dx-row
  td {
  border-top: 1px solid #dddddd;
}
.dx-widget .dx-datagrid .dx-datagrid-pager {
  border-top: none;
}
.dx-widget .dx-pager .dx-page-size {
  border-radius: 0;
  background-color: transparent;
}
.dx-widget .dx-pager .dx-page-size.dx-selection {
  color: #ffffff;
  background-color: #0373c6;
}
.dx-widget .dx-pager .dx-pages .dx-page {
  border-radius: 0;
  background-color: transparent;
}
.dx-widget .dx-pager .dx-pages .dx-page.dx-selection {
  color: #0373c6;
  border-bottom: 2px solid #0373c6;
}
.dx-widget.type-th table th {
  text-align: center !important;
}
.dx-widget.type-ct table th,
.dx-widget.type-ct table td {
  text-align: center !important;
}
.dx-datebox {
  flex: 1;
}
.dx-datebox-calendar .dx-dropdowneditor-icon:before {
  transform: rotate(0) !important;
  border: none !important;
  content: "\f073" !important;
}

.dx-datebox-wrapper-calendar .dx-popup-content {
  margin-top: 0px !important;
  margin-top: 0rem !important;
  margin-right: 0px !important;
  margin-right: 0rem !important;
  margin-bottom: 0px !important;
  margin-bottom: 0rem !important;
  margin-left: 0px !important;
  margin-left: 0rem !important;
}
.dx-datebox-wrapper-calendar .dx-popup-content .dx-calendar {
  margin-top: 15px !important;
  margin-top: 0.9375rem !important;
  margin-right: 15px !important;
  margin-right: 0.9375rem !important;
  margin-bottom: 15px !important;
  margin-bottom: 0.9375rem !important;
  margin-left: 15px !important;
  margin-left: 0.9375rem !important;
}
.dx-textarea .dx-texteditor-input {
  border: 1px solid #dddddd;
  border-radius: 0;
}
.dx-textarea.dx-state-focused .dx-texteditor-input {
  border: 1px solid #000000;
}
.dx-dropdowneditor.dx-editor-outlined {
  border: 1px solid #dddddd !important;
  border-radius: 0 !important;
}
.dx-dropdowneditor input {
  height: 35px;
  border: none !important;
  background-color: #fff !important;
}
.dx-dropdowneditor .dx-dropdowneditor-icon:before {
  content: "";
  /* display: block; */
  /* position: absolute; */
  /* display: flex; */
  width: 7px;
  width: 0.4375rem;
  height: 7px;
  height: 0.4375rem;
  /* top: 22px;
  top: 1.375rem;
  left: 21px;
  left: 1.3125rem; */
  text-align: center !important;
  justify-self: center !important;
  align-self: center !important;
  justify-content: center !important;
  align-items: center !important;
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
  transform: rotate(45deg);
  z-index: 2;
}
.calendar-view .dx-calendar {
  width: 100%;
  height: 500px;
  height: 31.25rem;
}
.calendar-view .dx-calendar-cell {
  text-align: right;
  vertical-align: top;
}
.calendar-view .dx-calendar-body {
  top: 70px;
  top: 4.375rem;
}
.calendar-view .dx-calendar-body table th {
  border: 1px solid #dddddd;
}
.calendar-view .dx-calendar-body table td {
  position: relative;
  border: 1px solid #dddddd;
}
.calendar-view .dx-calendar-body table td.has span:after {
  content: "R";
  display: block;
  position: absolute;
  left: 10px;
  left: 0.625rem;
  bottom: 10px;
  bottom: 0.625rem;
  width: 26px;
  width: 1.625rem;
  height: 26px;
  height: 1.625rem;
  line-height: 26px;
  line-height: 1.625rem;
  font-size: 14px;
  font-size: 0.875rem;
  color: #ffffff;
  text-align: center;
  border-radius: 100%;
  border: 1px solid #ffffff;
  background-color: #0373c6;
}
.calendar-view .dx-calendar-navigator {
  height: 60px;
  height: 3.75rem;
}
.calendar-view .dx-calendar-navigator .dx-button-text {
  font-size: 35px;
  font-size: 2.1875rem;
}
@media all and (max-width: 480px) {
  .dx-datebox .dx-texteditor-input-container {
    flex: none;
    width: 100%;
  }
  .dx-datebox .dx-texteditor-input-container .dx-texteditor-input {
    padding-right: 10px !important;
    padding-right: 0.625rem !important;
  }
  .dx-datebox .dx-texteditor-buttons-container {
    display: none;
  }
  .calendar-view .dx-calendar {
    height: 350px;
    height: 21.875rem;
  }
  .calendar-view .dx-calendar-body table td.has span:after {
    left: 2px;
    left: 0.125rem;
    bottom: 2px;
    bottom: 0.125rem;
    width: 20px;
    width: 1.25rem;
    height: 20px;
    height: 1.25rem;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 16px;
    line-height: 1rem;
  }
  .calendar-view .dx-calendar-navigator {
    height: 40px;
    height: 2.5rem;
  }
  .calendar-view .dx-calendar-navigator .dx-button-text {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
